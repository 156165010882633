import React from "react";
import {Link} from "react-router-dom";


export default (props:any) => {

    return (<>
        {!props.anonymous &&
            <a href={`https://app.black-belt.io/business/${props?.blackBeltBusinessClientId}`}>
                <button className={`${props?.navbar ? "button-select-candidate-navbar" : "button-select-candidate"}`}>
                    Dashboard
                    <img className={"button-select-candidate__img-big"}
                         src={"https://static.myshortlist.co/dashboard-white.svg"} alt={"dashboard"}/>
                </button>
            </a>
        }

        {props.anonymous &&
            <Link to={`/new/account/${props?.token}${props.search}`}>
                <button className={`${props?.navbar ? "button-select-candidate-navbar" : "button-select-candidate"}`}>
                    Dashboard
                    <img className={"button-select-candidate__img-big"}
                         src={"https://static.myshortlist.co/flash-white.svg"} alt={"flash"}/>
                </button>
            </Link>
        }

    </>);
}