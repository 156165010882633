import React, {useContext} from "react";
import {InlineWidget} from "react-calendly";
import NavbarNewAccount from "./NavbarNewAccount";

export default (props: any) => {

    return (<>
        <div className={"new-account-container-demonstration"}>
            <NavbarNewAccount step={3} setStep={props.setStep}/>

            <div className={"new-account-information"}>
                <img className={"margin3"} src={"https://static.myshortlist.co/gabrielle.svg"} alt={"gabrielle"}/>
                <div>
                    <div className={"new-account-information-title"}> Gabrielle</div>
                    <div className={"new-account-information-description"}> Tout se passe comme
                        prévu {props?.profile?.firstName} 👌
                        Nous avons créé le compte de {props?.profile?.businessName}.
                        Sélectionner le créneau qui vous convient pour que je vous fasse découvrir la plateforme Black
                        Belt 👇
                    </div>
                </div>
            </div>
            <br/>

            {/*<InlineWidget url="https://calendly.com/gabrielle-alcufrom/15?month=2021-11"*/}
            {/*              pageSettings={{hideLandingPageDetails: true}}/>*/}


            {/*<InlineWidget url="https://calendly.com/hello_black-belt?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=063ef9"*/}
            {/*              pageSettings={{hideLandingPageDetails: true}}/>*/}


            {/*<InlineWidget url=" https://calendly.com/hadassa-1/15min?hide_event_type_details=1&hide_gdpr_banner=1"*/}
            {/*              pageSettings={{hideLandingPageDetails: true}}/>*/}

            <InlineWidget url="https://calendly.com/gabrielle-alcufrom/15"
                          pageSettings={{hideLandingPageDetails: true}}/>

            <br/>


        </div>
    </>);
}