import React, {useContext, useState} from "react";
import NavbarNewAccountMobile from "./NavbarNewAccountMobile";
import axios from "axios";
import {notification} from "../../../utils/notification";
import {devLog} from "../../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: any) => {
    const[isSubmit, setIsSubmit]=useState(false);
    const isButtonStatusGood = props.profile.businessName && props.profile.businessName && props.profile.nRecruitments;
    const appContext: IAppContext = useContext(AppContext) as IAppContext;


    const updateNewAccount = async () => {
        try {

            const {
                status,
                data
            } = (await axios.put(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/update/client/account`, {
                mail:props.profile.mail,
                firstName:props.profile.firstName,
                lastName:props.profile.lastName,
                phone:props.profile.phone,
                businessName:props.profile.businessName,
                function:props.profile.function,
                nRecruitments:props.profile.nRecruitments,
                token:props.profile.token,
                clientId:props.profile.clientId,
            }, {
                withCredentials: true
            })).data;

            if(status==="alreadyExists"){
                props.setStep(4);
            }
            if (status !== "ok") {
                return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            }

            devLog(status, data);
            props.setStep(4);
            return notification(appContext.dispatchPanels, "success", "success");


        } catch (error) {
            devLog(error);
            notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
        }
    }


    const createNewAccount = async () => {
        try {

            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/new/client/account`, {
                profile: props.profile,
            }, {
                withCredentials: true
            })).data;

            if(status==="alreadyExists"){
                props.setStep(4);
            }

            if (status !== "ok") {
                notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            }

            devLog(status, data);
            props.setStep(4);
            notification(appContext.dispatchPanels, "success", "success");



        } catch (error) {
            devLog(error);
            notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    return (<>


        <NavbarNewAccountMobile step={2} setStep={props.setStep}/>
        <button className={"button-back-mobile"} onClick={() => {
            props.setStep(2)
        }}>
            <img src={"https://static.myshortlist.co/Arrow-Back-Black.svg"}
                 alt={"backward"}/></button>
        <div className={"new-account-container-mobile"}>
            <div className={"new-account-information"}>
                <img className={"margin3"} src={"https://static.myshortlist.co/gabrielle.svg"} alt={"gabrielle"}/>
                <div>
                    <div className={"new-account-information-title"}> Gabrielle</div>
                    <div className={"new-account-information-description"}> Nous avons déjà fait la moitié du
                        chemin {props?.profile?.firstName}
                        . Maintenant,
                        parlez-moi un peu de l'entreprise que vous représentez.
                    </div>
                </div>
            </div>


            <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Société*</div>
                <input className={"new-account-information-item-input"}
                       value={props.profile.businessName} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "businessName", value: e.target.value}
                    })
                }}/>
            </div>
            { isSubmit && !props.profile.businessName && <div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre société </div>}


            <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Fonction*</div>
                <input className={"new-account-information-item-input"}
                       value={props.profile.function} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "function", value: e.target.value}
                    })
                }}/></div>
            { isSubmit && !props.profile.function && <div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre fonction </div>}


            <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Nombre de recrutements par an*</div>
                <select className={"new-account-information-item-select"}
                        value={props.profile.nRecruitments} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "nRecruitments", value: e.target.value}
                    })
                }}>
                    <option value={'1 à 5'}> 1 à 5  </option>
                    <option value={'6 à 20'}> 6 à 20  </option>
                    <option value={'20 à 50'}> 20 à 50  </option>
                    <option value={'50 à 200'}> 50 à 200  </option>
                    <option value={'supérieur à 200'}> supérieur à 200  </option>

                </select>
            </div>

            <br/>
            <button className={"new-account-start__button-blue"} onClick={() => {
                if (!isButtonStatusGood) {
                    setIsSubmit(true);
                }
                else{
                    updateNewAccount()
                }
            }
            }>
                <div className={"new-account-start__button-blue-content"}>Valider</div>
                <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                     alt={"arrow"}/>
            </button>

        </div>

    </>);
}