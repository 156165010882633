import React from "react";
import {Link} from "react-router-dom";
import {GoogleLogin} from "react-google-login";


export default (props: any) => {

    return (<>

        <div className={"ellipse-mobile"}>
            <Link
                to={`/card/new/${props.token}${props.search}`}>
                <button className={"button-back-new-account"}>
                    <img src={"https://static.myshortlist.co/back.png"}
                         alt={"backward"}/></button>
            </Link>

            <img className={"logo-mobile-new_account"} src={"https://static.myshortlist.co/logo-new-account-mobile.svg"}
                 alt={"logo"}/>

            <div className={"img-gradient-new-account-mobile"}>
            <img  src={props?.candidate?.avatarUrl} alt={"clementine"}/>
            </div>
            <div className={"ellipse-img-jeanne-mobile"} >
                <img src={"https://static.myshortlist.co/jeanne.png"} alt={"jeanne"}/>
                <div className={"ellipse-img-name-mobile"}> Jeanne</div>
            </div>
            <div className={"ellipse-img-armand-mobile"}>
                <img  src={"https://static.myshortlist.co/armand.png"} alt={"armand"}/>
                <div className={"ellipse-img-name-mobile"}>Armand</div>
            </div>
            <div  className={"ellipse-img-coline-mobile"}>
                <img src={"https://static.myshortlist.co/coline.png"} alt={"coline"}/>
                <div className={"ellipse-img-name-mobile"}>Coline</div>
            </div>
        </div>

        <div className={"new-account-creation-container-mobile"}>


            <div className={"new-account-start-mobile__title"}> Créer un compte pour contacter {props?.candidate?.firstName} {props?.candidate?.lastName?.toUpperCase()[0]}.</div>

            <div className={"new-account-start-mobile__content"}> Il faut moins de 10 minutes pour remplir les
                informations
                nécessaires à l'inscription et accéder à d’autres candidats disponibles.
            </div>

            {/*<button className={"new-account-start__button"}>*/}
            {/*    <img src={"https://static.myshortlist.co/logo-linkedin.png"} alt={"logo-linkedin"}/>*/}
            {/*    <div className={"new-account-start__button-text"}> Connexion avec LinkedIn</div>*/}
            {/*</button>*/}

            <GoogleLogin
                clientId={props?.clientId}
                buttonText="Inscription avec Google"
                onSuccess={props?.responseGoogle}
                onFailure={props?.responseGoogle}
                cookiePolicy={"single_host_origin"}
                style={{marginTop: '100px'}}
                isSignedIn={false}
                className={"new-account-start__button"}/>

            <div className={"new-account-start-mobile__email-border"}></div>

            <div className={"new-account-start-mobile__email"}> Ou s’inscrire avec son e-mail</div>

            <button className={"new-account-start__button-blue"} onClick={() => {
                props.setStep(2);
            }}>
                <div className={"new-account-start__button-blue-content"}>Créer un compte Black Belt</div>
                <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                     alt={"arrow"}/>
            </button>

        </div>

    </>);
}