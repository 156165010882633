import React from "react";
import OurFeeling from "./OurFeeling";
import Wants from "./Wants";
import Experience from "./Experience";
import AboutTheCandidate from "./AboutTheCandidate";
import VideoOnMobile from "./VideoOnMobile";
import InformationsOnMobile from "./InformationsOnMobile";
import MatchingScoreOnMobile from "./MatchingScoreOnMobile";
import NameAndPositionOnMobile from "./NameAndPositionOnMobile";
import NavbarMobile from "./NavbarMobile";
import Tchat from "./Tchat";
import SocialNetworkOnMobile from "./SocialNetworkOnMobile";
import FlashcardNavbar from "./FlashcardNavbar";

export default (props: {
    token:any;
    anonymous: boolean;
    data: any;
    setIndex?:any;
    proposition?:any;
    index?:number;
    setSelected?:any;
    selected?:any;
    blackBeltBusinessClientId?:string;
    blackBeltBusiness?:any;
}) => {

    const search=window.location.search;
    const params = new URLSearchParams(window.location.search);
    const job = params.get('j') ?? "Business Developer";
    const company = params.get('c') ?? "Vous";
    const email = params.get('e') ?? "";
    const matching = params.get('m') ?? "91";
    const name = params.get('n') ?? "";
    const firstName=params.get('f') ?? "";

    return (<>

        {/*<button className={"button-back"}>*/}
        {/*    <img src={"https://static.myshortlist.co/back.png"} alt={"backward"}/>*/}
        {/*</button>*/}


        <VideoOnMobile video={props.data?.candidate?.extendedProfile?.videoUrl}
                       avatarUrl={props.data?.candidate?.avatarUrl}
                       anonymous={props?.anonymous}
                       token={props.token}
                       candidate={props.data?.candidate}
                       search={search}
                       blackBeltBusinessClientId={props?.blackBeltBusinessClientId}
                       setIndex={props.setIndex}
                       proposition={props?.proposition}
                       index={props?.index}/>

        <div className={"container-smartphones-tablets"}>

            <div className={"line"}> </div>

            <NameAndPositionOnMobile firstName={props.data?.candidate?.firstName}
                                     lastName={props.data?.candidate?.lastName}
                                     positionWanted={props.data?.candidate?.positionWantedString}
                                     global={props.data?.candidate?.global}
                                     salary={props.data?.candidate?.salaryMin}
                                     job={job}/>

            <InformationsOnMobile currentSalary={props.data?.candidate?.currentSalary}
                                  yearsOfExperience={props.data?.candidate?.yearsOfExperience}
                                  availability={props.data?.candidate?.availability}
                                  location={props.data?.candidate?.locationString}
            />


            {!props.anonymous && <div className={""}>
            <SocialNetworkOnMobile anonymous={props.anonymous}
                                   token={props.token}
                                   candidate={props.data?.candidate}
                                   index={props.index}
                                   search={search}
                                   pc={false}/>
            </div>}



            <AboutTheCandidate about={props.data?.candidate?.extendedProfile?.personal}
                               women={props.data?.candidate?.extendedProfile?.women}
                                pc={false}/>

            {/*<FlashcardNavbar tas={props.data?.candidate?.tas}*/}
            {/*                 date={props.data?.candidate?.lastInterview}*/}
            {/*                 pc={false}/>*/}

            <OurFeeling feeling={props.data?.candidate?.extendedProfile?.feeling}
                        pc={false}/>

            <Wants wants={props.data?.candidate?.extendedProfile?.wants}
                   pc={false}/>

            <br/>
            <MatchingScoreOnMobile
                firstName={props.data?.candidate?.firstName}
                global={props.data?.candidate?.global}
                autonomy={props.data?.candidate?.autonomy}
                creativity={props.data?.candidate?.creativity}
                culture={props.data?.candidate?.culture}
                hardSkills={props.data?.candidate?.hardSkills}
                softSkills={props.data?.candidate?.softSkills}
                avatarUrl={props.data?.candidate?.avatarUrl}
                company={company}
                matching={matching}

            />

            <Experience experience={props.data?.candidate?.extendedProfile?.experience}
                        pc={false}
                        anonymous={props?.anonymous}/>

            { !props?.anonymous &&
                <Tchat candidacy={props?.data?.candidacy}
                       business={props.blackBeltBusiness}
                       pc={false}/>
           }

           <NavbarMobile anonymous={props.anonymous}
                         token={props.token}
                         setIndex={props.setIndex}
                         proposition={props?.proposition}
                         index={props?.index}
                         firstName={props?.data?.candidate?.firstName}
                         candidate={props?.data?.candidate}
                         selected={props?.selected}
                         setSelected={props?.setSelected}
                         displayVideo={false}
                         candidacyId={props?.data?.candidacy?._id}
                         search={search}
           />
        </div>
    </>)
}