import {format} from "date-fns";

export function isEmailValid(email: any): email is string {
    try {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
            email,
        );
    } catch (error) {
        return false;
    }
}


export function displayDate(original:any) {
    try {
        if(!original) return '';
        let formatDate="dd/MM/yyyy";
        return( format(new Date(original), formatDate));
    }
    catch (error) {
        return '';
    }
}

export function getLocation(remote:any, roaming:any, roamingPlace:any, location:any) {

    if(remote === "yes") {
        return "Le poste est en télétravail";
    }

    if(roaming) {
        return roamingPlace;
    }

    if(!location) {
        return "";
    }

    if(!location.country && !location.postalCode) {
        return "Lieu non déterminé";
    }

    let temporaryLocation = "";

    if(location.country) temporaryLocation += location.country;
    if(location.city) temporaryLocation += ', ' + location.city;
    if(location.postalCode) temporaryLocation += ', ' + location.postalCode;

    return temporaryLocation;

}

export function shortenText(text:string, size:number) {
    if(!text) {
        return text;
    }
    if(text.length < size) {
        return text;
    }
    return `${text.slice(0,size)} ...`;
}


