import React from "react";

export default (props: any) => {
    return (<>

        <div className={"ellipse"}>


            <div className={"page-apply-description"}>
                <img className={""} src={"https://static.myshortlist.co/logo.png"} alt={"logo"}/>

                <div className={"page-apply-position-wanted"}> Vous postulez pour le poste
                    de {props.mission?.positionWanted}</div>

                <div className={"page-apply-rocket"}>
                    <div className={"page-apply-rocket-text"}> C'est parti</div>
                    <img className={"page-apply-rocket-img"} src={"https://static.myshortlist.co/rocket.png"}
                         alt={"rocket"}/>
                </div>

            </div>

        </div>


        <div className={"page-to-apply-success-container"}>

            <div className={"new-account-information"}>
                <div>


                    { props?.candidacyStatus==="tooManyApplies" &&
                    <div className={"page-apply-success-description"}>
                        Vous avez déjà postulé pour ce poste !
                        L'équipe black-belt revient vers vous au plus vite...
                    </div>}

                    { props?.candidacyStatus==="noData" &&
                    <div className={"page-apply-success-description"}>
                        Certaines données obligatoires n'ont pas été fournies!
                        Votre candidature n'a malheureusement pas pu etre enregistrée ...
                    </div>}

                    { props?.candidacyStatus!=="tooManyApplies" &&  props?.candidacyStatus!=="noData"&&
                    <div className={"page-apply-success-description"}>
                        Votre candidature a bien été enregistrée !
                        L'équipe black-belt revient vers vous au plus vite...
                    </div>}


                </div>
            </div>

        </div>
        </>
    )
}