import {Link} from "react-router-dom";
import ButtonX from "./ButtonX";
import ButtonSelectCandidate from "./ButtonSelectCandidate";
import React from "react";

export default (props: any) => {


    return (<>

            <div className={"navbar-on-mobile"}>
                {!props?.displayVideo && <ButtonX anonymous={props?.anonymous}
                                              setIndex={props.setIndex}
                                              proposition={props?.proposition}
                                              index={props?.index}
                                              token={props.token}
                                              search={props.search}/> }
              <ButtonSelectCandidate firstName={props?.firstName}
                                     navbar={true}
                                     anonymous={props?.anonymous}
                                     token={props.token}
                                     candidate={props?.candidate}
                                     selected={props?.selected}
                                     setSelected={props?.setSelected}
                                     candidacyId={props?.candidacyId}
                                     search={props.search}
              />

            </div>
        </>
    );
}