import React, {useState} from "react";

export default (props:{
    about:any;
    pc:any;
    women:boolean;
}) => {

    const [readMore,setReadMore]=useState(false);


    return (<>
        <div className={`${props.pc ? "section__title":"section__title-on-mobile"}`}>{props.women &&<>À propos de cette candidate</>}
            {!props.women &&<>À propos de ce candidat</>}</div>
        {readMore && <div className={`${props.pc ? "section__content":"section__content-on-mobile"}`}
                          style={{whiteSpace: "pre-wrap"}}>
            {props?.about}
            <button className={"read-more"} onClick={()=>{setReadMore(false)}}>Lire moins. </button>
        </div>}
        {!readMore &&  <div className={`${props.pc ? "section__content":"section__content-on-mobile"}`}  style={{whiteSpace: "pre-wrap"}}>
            {props?.about?.substring(0, 300)}
            {(props?.about?.length> 300) && <> ... <button className={"read-more"} onClick={()=>{setReadMore(true)}}>Lire plus. </button></>}
        </div>}

    </>);

}

