import React, {useState} from "react";
import {Formik, Form, Field, ErrorMessage} from "formik";


export default (props: any) => {

    // function format(x:any) {
    //     return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // }
    //
    // console.log(format('1234567890'));
    //
    // document.getElementById('salary')?.addEventListener('input', function (e) {
    //     // @ts-ignore
    //     e.target?.value = e.target?.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    // });

    const [applyStep, setApplyStep]: any = useState(1);


    return (<>

            <div className={"ellipse"}>


                <div className={"page-apply-description"}>
                    <img className={"page-apply-logo"} src={"https://static.myshortlist.co/logo.png"} alt={"logo"}/>

                    <div className={"page-apply-position-wanted"}> Vous postulez pour le poste
                        de {props.mission?.positionWanted}</div>

                    <div className={"page-apply-rocket"}>
                        <div className={"page-apply-rocket-text"}> C'est parti</div>
                        <img className={"page-apply-rocket-img"} src={"https://static.myshortlist.co/rocket-pink.png"}
                             alt={"rocket"}/>
                    </div>

                </div>

            </div>


            <div className={"page-to-apply-container"}>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Prénom*</div>
                    <input type={"text"} className={"new-account-information-item-input"}
                           value={props.firstName} onChange={(e) => props.setFirstName(e.target.value)}/>
                </div>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Nom*</div>
                    <input type={"text"} className={"new-account-information-item-input"}
                           value={props.name} onChange={(e) => props.setName(e.target.value)}/>

                </div>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Email*</div>
                    <input type={"text"} className={"new-account-information-item-input"}
                           value={props.mail} onChange={(e) => props.setMail(e.target.value)}/>
                </div>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Téléphone*</div>
                    <input type={"text"} className={"new-account-information-item-input"}
                           value={props.phone} onChange={(e) => props.setPhone(e.target.value)}/>
                </div>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Lien linkedin*</div>
                    <input type={"text"} className={"new-account-information-item-input"}
                           value={props.linkedin} onChange={(e) => props.setLinkedin(e.target.value)}/>
                </div>

                <div className={"new-account-information-item"}>
                    <div className={"new-account-information-item-title"}>Salaire annuel brut souhaité*</div>
                    <div className={"row"}>
                        <input type={"number"} id={"salary"} className={"new-account-information-item-input"}
                               value={props.salaryWanted} onChange={(e) => props.setSalaryWanted(e.target.value)}

                        />
                    </div>
                </div>


                <br/>

                {((props.firstName && !props.isFirstNameOk) || !props.isLastNameOk || (props.mail!=="" &&!props.isEmailOk) || !props.isPhoneOk || !props.isLinkedinOk || !props.isSalaryOk) &&
                <div
                    className={"page-apply-warning"}> {!props.isFirstNameOk && <>prénom,</>} {!props.isLastNameOk && <>nom,</>} {!props.isEmailOk && <>email,</>} {!props.isPhoneOk && <>téléphone,</>} {!props.isLinkedinOk && <>lien
                    linkedin,</>} {!props.isSalaryOk && <>salaire</>} non valide(s) </div>}
                <button className={"new-account-start__button-blue"} disabled={!props.isButtonStatusGood}
                        onClick={() => {
                            props.sendCandidacy()
                        }}>
                    <div className={"new-account-start__button-blue-content"}>Postuler</div>
                    <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                         alt={"arrow"}/>
                </button>

            </div>
        </>
    )
}
