import React, {useContext, useEffect, useState} from "react";
import FlashcardV2MobileAndTablets from "./componentsFlashcardV2Page/FlashcardV2MobileAndTablets";
import FlashcardV2PC from "./componentsFlashcardV2Page/FlashcardV2PC";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {notification} from "../../utils/notification";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";

export default (props: any) => {

    const appContextV2: IAppContext = useContext(AppContext) as IAppContext;
    const token = props.match.params.token;
    const [mission, setMission] = useState();
    const [candidate, setCandidate] = useState();
    const [candidacy, setCandidacy] = useState();
    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");
                const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/get/anonymous/flashcard`, {
                    headers: {
                        token: token,
                        anonymous:"true",
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    setFetchStatus("ok");
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setFetchStatus("ok");
                devLog(data);
                setMission(data.mission);
                setCandidate(data.candidate);
                setCandidacy(data.candidacy);
                return notification(appContextV2.dispatchPanels, "success", "success");


            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    return (<PageLayout pageStatus={fetchStatus}>

        <div className={"hide-on-tablets-and-computers"}>
            <FlashcardV2MobileAndTablets anonymous={true}
                                         token={token}
                                         data={{
                                             mission: mission,
                                             candidate: candidate,
                                             candidacy: candidacy
                                         }}/>
        </div>

        <div className={"hide-on-smartphones"}>
            <FlashcardV2PC anonymous={true}
                           token={token}
                           data={{
                               mission: mission,
                               candidate: candidate,
                               candidacy: candidacy
                           }}/>
        </div>

    </PageLayout>)
}