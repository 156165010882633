import React, {useState, useEffect} from "react";
import axios from "axios";
import {shortenText, getLocation, displayDate} from "../../utils/typeGuards";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";



export default (props: any) => {

    const [openedPositions, setOpenedPositions] = useState([]);
    const [businessName, setBusinessName] = useState();
    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");

                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/ent/b/get/business/profile`, {
                    headers: {
                        slugname: 'blackbeltio',
                        slugnumber: "1",
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    console.log("error");
                    setFetchStatus("ok");
                }
                setFetchStatus("ok");

                setOpenedPositions(data.opennedPositions);
                setBusinessName(data.businessName);


            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    return (

        <PageLayout pageStatus={fetchStatus}>

        <div className={"navbar"}>
            <div className={"navbar_left"}>
                <img className={""} src={"https://static.myshortlist.co/logo.png"} alt={"logo"}/>
            </div>

            <div>
                {openedPositions?.length !== 0 &&  <div className={"page-career__title"}> Liste des postes ouverts</div>}
            </div>

        </div>


        <br/>
        <br/>


        {openedPositions?.length === 0 && <section className={"text--center business-page-jobs__title"}>
            Nous n'avons actuellement aucun poste ouvert
            <br/>
            <br/>
        </section>}

        {openedPositions?.length !== 0 &&
        <section className={"business-page-jobs"}>

            <br/>
            <br/>


            <div className={""}>
                {openedPositions?.slice(0).reverse().map((item: any, key: any) => (

                    <div className={"page-career--item"}>

                        <div className={"page-career__position-wanted"}> {item.positionWanted}
                            <span className={"margin3"}> {item.contract?.toUpperCase()}</span></div>
                        <div className={"page-career__date"}>
                            Posté le:  <span className={"margin3"}>  {displayDate(item.dateOfCreation)}</span>
                        </div>
                        <div className={"page-career__date"}>Lieu du poste:
                            <span className={"margin3"}> {getLocation(item.remote, item.roaming, item.roamingPlace, item.location)}</span>
                        </div>
                        <div className={"page-career__description"}>
                            {item.description}
                        </div>


                        <div className={"center"}>
                        <a target={"_blank"} href={`${window.location.origin}/apply/blackbeltio/1/${item.rank}/apply`}>
                        <button className={"new-account-start__button-blue"} >
                            <div className={"new-account-start__button-blue-content"}>Postuler</div>
                            <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                                 alt={"arrow"}/>
                        </button>
                        </a>
                        </div>

                    </div>

                ))}
            </div>
            <br/>
            <br/>

        </section>}


    </PageLayout>

    );
}







