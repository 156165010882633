import ButtonNextCandidate from "./ButtonNextCandidate";
import ButtonSelectCandidate from "./ButtonSelectCandidate";
import React from "react";
import {Link} from "react-router-dom";
import ButtonAllJobs from "./ButtonAllJobs";



export default (props: any) => {

    return (<>
        <div className={"navbar"}>
            <div className={"navbar_left"}>
                <img className={""} src={"https://static.myshortlist.co/logo-black.svg"} alt={"logo"}/>
            </div>

            <div>
                {!props?.displayVideo && <ButtonNextCandidate navbar={true}
                                                              anonymous={props.anonymous}
                                                              setIndex={props.setIndex}
                                                              token={props.token}
                                                              proposition={props?.proposition}
                                                              index={props?.index}
                                                              search={props?.search}/>}

                {props?.displayVideo && <Link to={`/card/${props.proposition[props?.index]?._id}`}>
                    <button className={"next-candidate-navbar"}>
                        Flashcard de {props?.firstName}
                        <img className={"margin3"} src={"https://static.myshortlist.co/arrow-groing-up-black.svg"}
                             alt={"arrow"}/>
                    </button>
                </Link>}

                <ButtonAllJobs navbar={true}
                               blackBeltBusinessClientId={props?.blackBeltBusinessClientId}
                               anonymous={props.anonymous}
                               token={props.token}
                               search={props?.search}
                />

            </div>


        </div>
    </>);
}