import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: any) => {
    const appContext: IAppContext = useContext(AppContext) as IAppContext;


    function getNextToken(proposition:any, index:number){
        if (index === (proposition?.length - 1)){
            return proposition[0]?._id;
        }
        else {
            return proposition[index + 1]?._id;
        }
    }

    function getOrigin(mode:any){
        if(mode=== "local"){
            return "http://localhost:3000"
        }
        else{
            return "https://app.black-belt.io"
        }
    }

    return (<>

        {props?.anonymous && <Link to={`/new/account/${props?.token}${props.search}`}>
            <button className={`${props?.navbar ? "next-candidate-navbar" : "next-candidate"}`}>
                Voir le candidat suivant
                <img className={"margin3"} src={"https://static.myshortlist.co/arrow-groing-up-black.svg"}
                     alt={"arrow"}/>
            </button>
        </Link>}

        {!props?.anonymous && props?.proposition?.length!==1 &&
            // <a href={`${getOrigin(appContext.appSession.app.mode)}/card/${getNextToken(props?.proposition,props?.index)}`}>
                <a href={`https://app.black-belt.io/card/${getNextToken(props?.proposition,props?.index)}`}>

                {/*<Link to={`${getNextToken(props?.proposition, props?.index)}`}>*/}
            <button className={`${props?.navbar ? "next-candidate-navbar" : "next-candidate"}`}
                    // onClick={() => {
                    //     if (props?.index === (props?.proposition?.length - 1)) {
                    //         props?.setIndex(0)
                    //     } else {
                    //         props?.setIndex((ps: number) => ps += 1);
                    //     }
                    // }}
            >
                Voir le candidat suivant
                <img className={"margin3"} src={"https://static.myshortlist.co/arrow-groing-up-black.svg"}
                     alt={"arrow"}/>
            </button>
             {/*</Link>*/}
            </a>
           }

    </>);
}