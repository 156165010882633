import React, {useContext, useEffect, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import {Link} from "react-router-dom";
import axios from "axios";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    const [windowStatus, setWindowStatus] = useState<'loading' | 'error' | 'forbidden' | 'loaded' | 'ok'>("loading");

    const [candidates, setCandidates] = useState([]);
    const missionId = appContext.appPanels.window.windowOptions.data?.missionId;

    useEffect(() => {
        (async() => {
            try {
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/candidates/for/mission`,
                    {
                        headers: {missionid: missionId},
                        withCredentials: true
                    })).data;
                setWindowStatus("ok")
                setCandidates(data);


            } catch(error) {
                console.log(error);
            }
        })();
    }, []);

    return (<WindowLayout size={"big"} pageStatus={windowStatus} windowTitle={"Candidats proposés pour ce poste"}>

        {(!candidates || candidates.length === 0) && <div className={"text--center text--grey"}>
            Aucun candidat à afficher
        </div>}

        {candidates?.map((item: any) => {
            let token=item.token;
            return (
                <>
                    <Link to={`/card/${token}`}>
                        <div className={`${item.kanbanColumn!=="10" ? "client-page__candidate" : "client-page__candidate--recruited"}`} onClick={() => {
                            appContext.dispatchPanels({
                                type: "CLOSE_WINDOW",
                                value: {
                                    type: "",
                                    data: {}
                                }
                            });
                        }}>
                            <div className={"client-page__avatar-container"}><img className={"client-page__avatar"}
                                                                                  src={item.avatarUrl}/></div>
                            <div className={"client-page__candidate__block__name"}>
                                <div className={"client-page__candidate__name"}>{item.firstName ?? ""} {item.lastName ?? ""}</div>
                                <div>{item.phone}</div>
                            </div>

                            <br/>
                            {item.kanbanColumn==="10" &&<div>
                                <div>Recruté</div>
                            </div>}

                            {item.kanbanColumn!=="10" && <div>
                                <div>Prochaine étape:</div>
                                <div>{item.blackBeltNextStep}</div>
                            </div>}

                        </div>
                    </Link>
                </>);
        })}



    </WindowLayout>);
}