import React, {useState} from "react";
import AboutTheCandidate from "./AboutTheCandidate";
import FlashcardNavbar from "./FlashcardNavbar";
import OurFeeling from "./OurFeeling";
import Wants from "./Wants";
import MatchingScore from "./MatchingScore";
import Experience from "./Experience";
import ButtonNextCandidate from "./ButtonNextCandidate";
import ButtonSelectCandidate from "./ButtonSelectCandidate";
import Informations from "./Informations";
import Video from "./Video";
import NameAndPosition from "./NameAndPosition";
import {Link} from "react-router-dom";
import NavbarPc from "./NavbarPc";
import Tchat from "./Tchat";
import ButtonAllJobs from "./ButtonAllJobs";
import ButtonForward from "./ButtonForward";
import SocialNetwork from "./SocialNetwork";

export default (props: {
    token: string;
    anonymous: boolean;
    data: any;
    setIndex?:any;
    proposition?:any;
    index?:number;
    setSelected?:any;
    selected?:any;
    job?:any;
    businessName?:any;
    paramsObject?:any;
    blackBeltBusinessClientId?:string;
    blackBeltBusiness?:any;
}) => {

    console.log("window.location.search",window.location.search);
    const search=window.location.search;
    const params = new URLSearchParams(window.location.search);
    const job = params.get('j') ?? props.data?.candidate?.positionWantedString;
    const company = params.get('c') ?? "Vous";
    const email = params.get('e') ?? "";
    const matching = params.get('m') ?? props.data?.candidate?.global;
    const name = params.get('n') ?? "";
    const firstName=params.get('f') ?? "";

    console.log("candidate flashcard pc",props?.data?.candidate, props?.data?.candidacy);

    return (<>

        <NavbarPc anonymous={props?.anonymous}
                  token={props?.token}
                  firstName={props?.data?.candidate?.firstName}
                  setIndex={props?.setIndex}
                  proposition={props?.proposition}
                  index={props?.index}
                  candidate={props?.data?.candidate}
                  selected={props?.selected}
                  setSelected={props?.setSelected}
                  displayVideo={false}
                  candidacyId={props?.data?.candidacy?._id}
                  search={search}
                  blackBeltBusinessClientId={props?.blackBeltBusinessClientId}
                  />

        <div className={"container"}>

            <div className={"container-left"}>
                {props.anonymous && <div id={"matching"}>
                    <div className={"section"}>
                        <MatchingScore
                            firstName={props.data?.candidate?.firstName}
                            global={props.data?.candidate?.global}
                            autonomy={props.data?.candidate?.autonomy}
                            creativity={props.data?.candidate?.creativity}
                            culture={props.data?.candidate?.culture}
                            hardSkills={props.data?.candidate?.hardSkills}
                            softSkills={props.data?.candidate?.softSkills}
                            avatarUrl={props.data?.candidate?.avatarUrl}
                            matching={matching}
                            company={company}
                        />
                    </div>
                </div>
                }

                <div className={"section"}>
                    <AboutTheCandidate about={props.data?.candidate?.extendedProfile?.personal}
                                       women={props.data?.candidate?.extendedProfile?.women}
                                       pc={true}
                    />
                </div>

                {!props.anonymous && <div className={"section-social-network"}>
                    <SocialNetwork anonymous={props.anonymous}
                                   token={props.token}
                                   candidate={props.data?.candidate}
                                   index={props.index}
                                   search={search}
                                   pc={true}/>
                </div>}

                {props.anonymous &&<div className={"section"} id={"navbar"}>
                    <FlashcardNavbar tas={props.data?.candidate?.tas}
                                     date={props.data?.candidate?.lastInterview}
                                     pc={true}/>
                </div>}


                <div id={"feeling"}></div>
                <OurFeeling feeling={props.data?.candidate?.extendedProfile?.feeling}
                            pc={true}/>


                <div id={"wants"}></div>
                <Wants wants={props.data?.candidate?.extendedProfile?.wants}
                       pc={true}/>

                {!props.anonymous && <div id={"matching"}>
                    <div className={"section"}>
                        <MatchingScore
                            firstName={props.data?.candidate?.firstName}
                            global={props.data?.candidate?.global}
                            autonomy={props.data?.candidate?.autonomy}
                            creativity={props.data?.candidate?.creativity}
                            culture={props.data?.candidate?.culture}
                            hardSkills={props.data?.candidate?.hardSkills}
                            softSkills={props.data?.candidate?.softSkills}
                            avatarUrl={props.data?.candidate?.avatarUrl}
                            matching={matching}
                            company={company}
                        />
                    </div>
                </div>
                }

                {/*<div className={"section"} id={"experience"}>*/}
                {/*    <Experience experience={props.data?.candidate?.extendedProfile?.experience}*/}
                {/*                pc={true} index={props?.index}/>*/}
                {/*</div>*/}

                { !props?.anonymous && <div className={"section"}>
                    <Tchat candidacy={props?.data?.candidacy}
                           business={props.blackBeltBusiness}
                           pc={true}/>
                </div>}

            </div>

            <div className={"container-right"}>

                <Video video={props.data?.candidate?.extendedProfile?.videoUrl}
                       avatarUrl={props.data?.candidate?.avatarUrl}
                       salary={props.data?.candidate?.salaryMin}
                       salaryVariableMin={props.data?.candidate?.salaryVariableMin}
                       anonymous={props.anonymous}
                       token={props.token}
                       candidate={props.data?.candidate}
                       index={props.index}
                       search={search}
                />


                <NameAndPosition firstName={props.data?.candidate?.firstName}
                                 lastName={props.data?.candidate?.lastName}
                                 positionWanted={props.data?.candidate?.positionWantedString}
                                 global={props.data?.candidate?.global}
                                 matching={matching}
                                 job={job}
                />

                <Informations currentSalary={props.data?.candidate?.currentSalary}
                              currentVariableSalary={props.data?.candidate?.currentVariableSalary}
                              yearsOfExperience={props.data?.candidate?.yearsOfExperience}
                              availability={props.data?.candidate?.availability}
                              location={props.data?.candidate?.locationString}
                />

                {/*<div className={"display-button"}>*/}
                    {props.anonymous && <Link to={`/new/account/${props.token}${search}`}>
                        <ButtonSelectCandidate firstName={props.data?.candidate?.firstName}
                                               navbar={false}
                                               anonymous={props.anonymous}
                                               token={props.token}
                                               candidate={props?.data?.candidate}
                                               selected={props?.selected}
                                               setSelected={props?.setSelected}
                                                search={search}/>
                        <ButtonNextCandidate navbar={false}
                                             anonymous={props.anonymous}
                                             setIndex={props.setIndex}
                                             token={props.token}
                                             proposition={props?.proposition}
                                             index={props?.index}
                                             search={search}/>
                    </Link>}
                    {!props.anonymous && <>
                        <ButtonSelectCandidate firstName={props.data?.candidate?.firstName}
                                               navbar={false}
                                               anonymous={props.anonymous}
                                               token={props.token}
                                               candidate={props?.data?.candidate}
                                               selected={props?.selected}
                                               setSelected={props?.setSelected}
                                               search={search}
                                               candidacyId={props?.data?.candidacy?._id}

                        />

                        <ButtonNextCandidate navbar={false}
                                             anonymous={props.anonymous}
                                             setIndex={props.setIndex}
                                             token={props.token}
                                             proposition={props?.proposition}
                                             index={props?.index}
                                             search={search}/>



                    </>}


                </div>

            </div>
        {/*</div>*/}


    </>)
}