import React from "react";
import {Link} from "react-router-dom";
import Contact from "./Contact";


export default (props: any) => {


    return (<div className={"section-video"}>

        <div className="img-gradient">
            <img src={props?.avatarUrl}/>
        </div>

        {props.anonymous && <Contact anonymous={props?.anonymous} token={props?.token} candidate={props?.candidate} search={props.search}/>}

        <div className={"salary_wanted"}>
                <img className={"euro__img"} src={"https://static.myshortlist.co/euro.svg"}
                     alt={"euro"}/>
            <div> Salaire souhaité {props?.salary}K {props.salaryVariableMin && <> + {props.salaryVariableMin}K</> }</div>
        </div>

        {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`} >
            <img className={"play-pc"} src={"https://static.myshortlist.co/play.svg"} aria-disabled={!props?.candidate?.extendedProfile?.videoUrl}/>
        </Link>}


        {!props.anonymous &&
        <Link to={`/video/${props.token}`} >
        <img className={"play-pc"} src={"https://static.myshortlist.co/play.svg"}/>
        </Link>
        }

    </div>);
}