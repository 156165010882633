import React, {useContext, useEffect, useReducer, useState} from "react";
import NewAccountCreation from "./componentsNewAccount/NewAccountCreation";
import Ellipse from "./componentsNewAccount/Ellipse";
import NewAccountInformations from "./componentsNewAccount/NewAccountInformations";
import NewAccountBusiness from "./componentsNewAccount/NewAccountBusiness";
import NewAccountDemonstration from "./componentsNewAccount/NewAccountDemonstration";
import dataReducerProfile from "./componentsNewAccount/dataReducerProfile";
import NewAccountCreationMobile from "./componentsNewAccount/NewAccountCreationMobile";
import NewAccountInformationsMobile from "./componentsNewAccount/NewAccountInformationsMobile";
import NewAccountBusinessMobile from "./componentsNewAccount/NewAccountBusinessMobile";
import NewAccountDemonstrationMobile from "./componentsNewAccount/NewAccountDemonstrationMobile";
import axios from "axios";
import {notification} from "../../utils/notification";
import {devLog} from "../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import NewAccountAlreadyExists from "./componentsNewAccount/NewAccountAlreadyExists";
import NewAccountAlreadyExistsMobile from "./componentsNewAccount/NewAccountAlreadyExistsMobile";

export {NewAccount};

const NewAccount = (props: any) => {


    const appContextV2: IAppContext = useContext(AppContext) as IAppContext;
    const token = props.match.params.token;
    const [candidate, setCandidate] = useState();
    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");
    const clientId = '291762120790-g61qh8h941p9bv99mov6pmtb705c8kv0.apps.googleusercontent.com';
    const search=window.location.search;
    const params = new URLSearchParams(window.location.search);
    const job = params.get('j') ?? "Business Developer";
    const company = params.get('c') ?? "Vous";
    const email = params.get('e') ?? "";
    const matching = params.get('m') ?? "91";
    const name = params.get('n') ?? "";
    const firstName=params.get('f') ?? "";

    const [step, setStep] = useState(2);
    const [profile, dispatchProfile] = useReducer(dataReducerProfile, {
        mail:email,
        firstName:firstName,
        lastName:name,
        phone:"",
        businessName:company,
        function:"",
        nRecruitments:"1-5",
        token:"",
        clientId:""
    });


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");
                const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/get/anonymous/flashcard`, {
                    headers: {
                        token: token
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    setFetchStatus("ok");
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setFetchStatus("ok");
                devLog(data);
                setCandidate(data.candidate);
                return notification(appContextV2.dispatchPanels, "success", "success");


            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);


    const responseGoogle = (response:any) => {
        console.log(response);
        dispatchProfile({type: "UPDATE_VALUE", value: {key: "mail", value: response?.profileObj.email }});
        dispatchProfile({type: "UPDATE_VALUE", value: {key: "lastName", value: response?.profileObj.familyName}});
        dispatchProfile({type: "UPDATE_VALUE", value: {key: "firstName", value: response?.profileObj.givenName}});
        dispatchProfile({type: "UPDATE_VALUE", value: {key: "token", value: response?.accessToken}});
        setStep(2);
    };

    const logout=() => {
        console.log("logout");
    };

    return (<PageLayout pageStatus={fetchStatus}>

        <div className={"hide-on-smartphones"}>
        <Ellipse candidate={candidate}/>
        {step === 1 && <NewAccountCreation setStep={setStep} profile={profile} dispatchProfile={dispatchProfile} candidate={candidate}
                                           token={token} clientId={clientId} responseGoogle={responseGoogle} search={search} />}
        {step === 2 && <NewAccountInformations setStep={setStep} profile={profile} dispatchProfile={dispatchProfile} candidate={candidate}
                                               token={token} search={search}/>}
        {step === 3 && <NewAccountBusiness setStep={setStep} profile={profile} dispatchProfile={dispatchProfile}/>}
        {step === 4 && <NewAccountDemonstration setStep={setStep} profile={profile} dispatchProfile={dispatchProfile}/>}
        {step === 5 && <NewAccountAlreadyExists/>}

        </div>

        <div className={"hide-on-tablets-and-computers"}>
            {step === 1 && <NewAccountCreationMobile setStep={setStep} profile={profile} dispatchProfile={dispatchProfile} candidate={candidate}
                                                     token={token} clientId={clientId} responseGoogle={responseGoogle} search={search}/>}
            {step === 2 && <NewAccountInformationsMobile setStep={setStep} profile={profile} dispatchProfile={dispatchProfile} candidate={candidate}
                                                         token={token} search={search}/>}
            {step === 3 && <NewAccountBusinessMobile setStep={setStep} profile={profile} dispatchProfile={dispatchProfile}/>}
            {step === 4 && <NewAccountDemonstrationMobile setStep={setStep} profile={profile} dispatchProfile={dispatchProfile}/>}
            {step === 5 && <NewAccountAlreadyExistsMobile/>}
        </div>
    </PageLayout>);
}


