import React, {useEffect, useState} from "react";

export default (props: any) => {

    // let heightExperience = document.getElementById("experience--items")?.style.height;
    // let finalHeightExperience = parseInt(heightExperience ?? "0");
    // console.log("heightExperience", heightExperience)
    // console.log("finalHeightExperience", finalHeightExperience);

    const [experienceFormat, setExperienceFormat]: any = useState([])

    useEffect(() => {
         setExperienceFormat([]);
        for (let item of props?.experience ?? []) {
            setExperienceFormat((ps: any) => ([...ps, {...item, isOpened: true}]));
        }

    }, [props?.experience, props?.index])

    const openMission=(key:number) =>{
        let items = [...experienceFormat];
        let item = {...experienceFormat[key]};
        item.isOpened = true;
        items[key] = item;
        setExperienceFormat(items);
    }

    const closeMission=(key:number) =>{
        let items = [...experienceFormat];
        let item = {...experienceFormat[key]};
        item.isOpened = false;
        items[key] = item;
        setExperienceFormat(items);
    }

    return (<>

        <div className={`${props.pc ? "section__title":"section__title-on-mobile"}`}> Ses expériences</div>
        <div className={`${props.pc ? "experience--container" : "experience--container-on-mobile"}`}>

            <div className={"experience-row"}>
                {/*<div className={"border-blue"} style={{height: heightExperience}}> </div>*/}
                <div className={`${props.pc ? "section__content" : "section__content-on-mobile"}`}
                     id={"experience--items"}>{experienceFormat?.map((item: any, key: number) => {
                        return (<div className={"experience-row"}>

                            <div className={"hide-on-smartphones"}>
                            <div className={"circle-blue-container"}>
                                <div className={"circle-blue"}></div>
                            </div>
                            </div>

                            <div onClick={()=>{
                                if(!item.isOpened){openMission(key)}
                                if(item.isOpened){closeMission(key)}
                            }} className={`${props.pc ? "experience--item" : "experience--item-on-mobile"}`}>
                                {!item.isOpened && <button className={"button-transparent"} onClick={() => openMission(key)}>
                                    <img className={"experience--arrow"}
                                         src={"https://static.myshortlist.co/arrow-to-open.svg"} alt={"arrow-to-open"}/>
                                </button>}
                                {item.isOpened && <button className={"button-transparent"} onClick={() => closeMission(key)}>
                                    <img className={"experience--arrow"}
                                         src={"https://static.myshortlist.co/arrow-to-close.svg"} alt={"arrow-to-close"}/>
                                </button>}

                                <div
                                    className={`${props.pc ? "experience__position" : "experience__position-on-mobile"}`}>{item.position}</div>
                                <div
                                    className={`${props.pc ? "experience__company" : "experience__company-on-mobile"}`}>{item.company}</div>
                                <div
                                    className={`${props.pc ? "experience__company--description" : "experience__company--description-on-mobile"}`}>{item.companyDescription}</div>


                                {item.isOpened && <>
                                    <div>{item?.candidateMissions?.map((mission: any) => (

                                        <div className={"experience__candidate-mission"}>
                                            <img className={"experience__key__img"}
                                                 src={"https://static.myshortlist.co/right-for-mission.svg"} alt={"right"}/>
                                            {mission}
                                        </div>))}
                                    </div>

                                    <div>{item?.keys?.map((key: any) => (
                                        <div className={"experience__key"}>
                                            <img className={"experience__key__img"}
                                                 src={"https://static.myshortlist.co/thumb-up-white.svg"} alt={"thumb-up"}/>
                                            {key}
                                        </div>
                                    ))}
                                    </div>
                                </>}
                            </div>
                        </div>)
                    }
                )}
                    </div>
                    </div>
                    </div>

        {!props.pc && props.anonymous && <>
            <br/>
            <br/>
            <br/>
        </>}

                    </>);
                }