import React, {useEffect, useState} from "react";
import {
    Radar, RadarChart, PolarGrid,
    PolarAngleAxis, PolarRadiusAxis
} from 'recharts';


export default (props: any) => {

    const [displayInformation, setDisplayInformation] = useState(false);

    const data = [
        {name: 'Culture', value: props?.culture},
        {name: 'Soft skills', value: props?.softSkills},
        {name: 'Hard skills', value: props?.hardSkills},
        {name: 'Autonomie', value: props?.autonomy},
        {name: 'Créativité', value: props?.creativity},

    ];



    return (<>

        <div className={"row"} >

            <div className={"matching-score__title"}> Matching Score
                <button className={"button-matching-score"} onClick={() => {
                    setDisplayInformation(prevState => (!prevState))
                }}>
                    <img className={"matching-score__img"} src={"https://static.myshortlist.co/information.svg"}
                         alt={"information"}/>
                </button>

                {displayInformation && <div className={"matching-score__arrow-description row"}>
                    <div className="arrow-left"></div>
                    <div className={"matching-score__description"}> Technologie avancée de machine learning permettant de
                        mieux prédire la réussite d’un employé
                        dans l’entreprise en prenant en compte une multitudes de données.
                    </div>
                </div>
                }

            </div>



        </div>


        <div className={"matching-score-navbar"}>
            <div className={"rectangle-left"}></div>
            <div className={"rectangle-right"}></div>
            <div className={"matching-score-title"}> {props?.company}</div>
            <div className={"matching-score-title"}> {props?.matching}%</div>

            <div className={"matching-score-title row"}>
                {props?.avatarUrl && <div>
                    <img className={"avatar__img"} src={props?.avatarUrl}
                         alt={"avatar__img"}/>
                </div>}
                {!props?.avatarUrl && <div className={"avatar-on-matching-score"}>
                </div>
                }
                {props?.firstName}
            </div>
        </div>

        <div onClick={()=>{if(displayInformation){setDisplayInformation(false)}}} className={"matching-score-container"}>
            <div className={"matching-score-content"}>
                <br/>
                <br/>
                <RadarChart height={250} width={400}
                            outerRadius="80%" data={data}>
                    <PolarGrid/>
                    <PolarAngleAxis dataKey='name' display={"value"} className={"polar-angle-axis"} />
                    {/*<PolarRadiusAxis/>*/}
                    <Radar dataKey="value" stroke="#063EF9"
                           fill="#063EF9" fillOpacity={0.5}/>

                </RadarChart>

                <br/>
            </div>
        </div>


        <br/>

    </>);
}


