import React, {useContext, useEffect, useReducer, useRef, useState} from "react";
import {format} from "date-fns";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import dataReducerBlackBeltTchats from "./dataReducerBlackBeltTchats";


export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;
    const [blackBeltTchat, setBlackBeltTchat] = useState("");
    const [blackBeltTchats, dispathBlackBeltTchats]: [any, Function] = useReducer(dataReducerBlackBeltTchats, []);

    // const messagesEndRef: any = useRef(null);
    // const scrollToBottom = () => {
    //     messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    // };
    // useEffect(()=>scrollToBottom, [blackBeltTchats,dispathBlackBeltTchats,dataReducerBlackBeltTchats]);

    useEffect(() => {
        (async function () {
            try {
                if (!!props.candidacy?.blackBeltTchat) {
                    dispathBlackBeltTchats({
                        type: "INITIALIZE_DATA", value: props.candidacy?.blackBeltTchat
                    });
                } else {
                    {
                        dispathBlackBeltTchats({
                            type: "INITIALIZE_DATA", value: []
                        });
                    }
                }
            } catch (error) {
                devLog(error);
            }
        })();
    }, [props.candidacy]);

    return (<>

        <div className={`${props.pc ? "section__title" : "section__title-on-mobile"}`}> Echange avec Black Belt
            <span className={"section__sub-title"}>(communiquez avec nous)</span></div>
        <div className={`${props.pc ? "section__content" : "section__content-on-mobile"}`}>

            <div className={"tchat-container"}>
                {
                    blackBeltTchats?.length === 0 && <>
                        <div className={"text--center"}>Vous n'avez aucun message enregistré avec BlackBelt pour ce
                            candidat
                        </div>
                        <br/> <br/></>
                }

                {(blackBeltTchats ?? []).map((item: any) => (<div className={"tchat-item"}>
                    <div className={"tchat-avatar"}>
                        {(item.from === "DHA" || item.from==="HBE") &&
                            <img className={"tchat-avatar-img"} src={"https://static.myshortlist.co/dha.jpg"}/>}
                        {item.from === "GMA" &&
                            <img className={"tchat-avatar-img"} src={"https://static.myshortlist.co/gma.jpg"}/>}
                        {item.from === "SPT" &&
                            <img className={"tchat-avatar-img"} src={"https://static.myshortlist.co/spt.jpg"}/>}
                        {item.from === "JAD" &&
                            <img className={"tchat-avatar-img"} src={"https://static.myshortlist.co/jad.jpg"}/>}
                        {item.from === "LAB" &&
                            <img className={"tchat-avatar-img"} src={"https://static.myshortlist.co/lea3.jpg"}/>}
                        {item.from !== "DHA" && item.from !== "GMA" && item.from !== "SPT" && item.from !== "JAD" && item.from !== "LAB" && item.from !== "HBE" &&
                            <img className={"tchat-avatar-img"}
                                 src={"https://static.myshortlist.co/bb-background.png"}/>}
                    </div>
                    <div>
                        <div
                            className={"tchat-title"}> {item.from} - {item.dateOfCreation ? format(new Date(item.dateOfCreation), "dd.MM.yyyy") : ""}</div>
                        <div>{item.message}</div>
                    </div>
                </div>)).reverse()}

                {/*<div ref={messagesEndRef} />*/}
            </div>


            <br/>
            <div className={"row"}>
            <input className={`${props.pc ? "tchat-input" : "tchat-input-tel"}`} placeholder={"Votre message"} value={blackBeltTchat}
                   onChange={(e) => {
                       setBlackBeltTchat(e.target.value);
                   }}/>

            <button className={`${props.pc ? "button-tchat" : "button-tchat-tel"}`} disabled={!blackBeltTchat} onClick={async () => {
                const {
                    status,
                    data
                } = (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/bb/update/candidacy/tchat`,
                    {
                        candidacyId: props.candidacy._id,
                        message: blackBeltTchat,
                        firstName: props.business.name,
                        type: "client",
                    }
                    , {
                        timeout: 5000,
                        withCredentials: true,
                    })).data;

                if (status !== "ok") {
                    return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
                }

                dispathBlackBeltTchats({
                    type: "UPDATE_CANDIDACY", value: {
                        dateOfCreation: new Date(),
                        from: props.business.name,
                        message: blackBeltTchat
                    }
                });
                setBlackBeltTchat("");
                notification(appContext.dispatchPanels, "message envoyee", "success");

            }}>Envoyer
            </button>
            </div>
        </div>

        {!props.pc && <>
            <br/>
            <br/>
            <br/>
        </>}
    </>);
}