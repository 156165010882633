import React, {useState} from "react";
import {Link} from "react-router-dom";
import NavbarPc from "../componentsFlashcardV2Page/NavbarPc";
import ButtonX from "../componentsFlashcardV2Page/ButtonX";
import ButtonSelectCandidate from "../componentsFlashcardV2Page/ButtonSelectCandidate";
import NavbarMobile from "../componentsFlashcardV2Page/NavbarMobile";
import {AppLevelLoading} from "../AppLevel/AppLevelLoading";

export default (props: any) => {


    return (<>


        <div className={"display-video-mobile-container"}>


            <Link
                to={`/card/${props.token}`}>
                <button className={"button-back-new-account"}>
                    <img src={"https://static.myshortlist.co/back-blue.svg"}
                         alt={"backward"}/></button>
            </Link>

            <img className={"logo-mobile-new_account"} src={"https://static.myshortlist.co/logo-black.svg"}
                 alt={"logo"}/>


            {/*<video  className={"video-mobile"} playsInline={true} controls={true} autoPlay={false}*/}
            {/*        loop={false}*/}
            {/*        muted={false}>*/}
            {/*    <source src={props?.candidate?.extendedProfile?.videoUrl} type="video/mp4"/>*/}
            {/*</video>*/}

            {/*<div className={"video-container-mobile"}>*/}
            {/*             <video  className={"video-content-mobile"} playsInline={true} controls={true} autoPlay={false}*/}
            {/*                     loop={false}*/}
            {/*                     muted={false}>*/}
            {/*                 <source src={props?.candidate?.extendedProfile?.videoUrl} type="video/mp4"/>*/}
            {/*             </video>*/}
            {/*         </div>*/}

            <video className={"video-whithout-container-mobile"} playsInline={true} controls={true} autoPlay={false}
                   loop={false}
                   muted={false}>
                <source src={props?.candidate?.extendedProfile?.videoUrl} type="video/mp4"/>
            </video>

        </div>


        <NavbarMobile anonymous={props.anonymous}
                      token={props.token}
                      setIndex={props.setIndex}
                      proposition={props?.proposition}
                      index={props?.index}
                      firstName={props?.candidate?.firstName}
                      candidate={props?.candidate}
                      selected={props?.selected}
                      setSelected={props?.setSelected}
                      displayVideo={true}
                      candidacyId={props.candidacyId}
                      blackBeltBusinessClientId={props?.blackBeltBusinessClientId}

        />

    </>);
}