import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import {GoogleLogin, GoogleLogout} from "react-google-login";


export default (props: any) => {

    return (<>


        <div className={"new-account-container"}>
            <div className={"new-account-start__title"}> Créer un compte pour contacter {props?.candidate?.firstName} {props?.candidate?.lastName?.toUpperCase()[0]}.</div>

            <div className={"new-account-start__content"}> Il faut moins de 10 minutes pour remplir les informations
                nécessaires à l'inscription et accéder à d’autres candidats disponibles.
            </div>

            {/*<button className={"new-account-start__button"}>*/}
            {/*    <img src={"https://static.myshortlist.co/logo-linkedin.png"} alt={"logo-linkedin"}/>*/}
            {/*    <div className={"new-account-start__button-text"}> Connexion avec LinkedIn</div>*/}
            {/*</button>*/}

            <GoogleLogin
                clientId={props?.clientId}
                buttonText="Inscription avec Google"
                onSuccess={props?.responseGoogle}
                onFailure={props?.responseGoogle}
                cookiePolicy={"single_host_origin"}
                style={{marginTop: '100px'}}
                isSignedIn={false}
                className={"new-account-start__button"}/>

            {/*<GoogleLogout*/}
            {/*    clientId={clientId}*/}
            {/*    buttonText="Deconnexion"*/}
            {/*    onLogoutSuccess={logout}*/}
            {/*   />*/}

            <div className={"new-account-start__email-border"}></div>

            <div className={"new-account-start__email"}> Ou s’inscrire avec son e-mail</div>

            <button className={"new-account-start__button-blue"} onClick={() => {
                props.setStep(2);
            }}>
                <div className={"new-account-start__button-blue-content"}>Créer un compte Black Belt</div>
                <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                     alt={"arrow"}/>
            </button>

            <Link
                to={`/card/new/${props.token}${props.search}`}>
                <div className={"new-account-start__return-flashcard"}>
                    <img className={""} src={"https://static.myshortlist.co/return.svg"}
                         alt={"arrow"}/>

                    <div className={"new-account-start__return-flashcard-content"}>Revenir sur la flashcard de {props?.candidate?.firstName}

                    </div>
                </div>
            </Link>

        </div>

    </>);
}