import React from "react";
import { Link } from "react-router-dom";


export default (props:any) => {

    function getNextToken(proposition:any, index:number){
        if (index === (proposition?.length - 1)){
            return proposition[0]?._id;
        }
        else {
            return proposition[index + 1]?._id;
        }
    }
    return (<>
        {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
        <button className={"button-x"}>
            <img className={"button-next-candidate-mobile__img"} src={"https://static.myshortlist.co/next-mobile.svg"} alt={"flash"} />
            </button>
        </Link>}

        {!props.anonymous && props.proposition?.length!==1 &&
            <a href={`https://app.black-belt.io/card/${getNextToken(props?.proposition,props?.index)}`}>

            <button className={"button-x"}
            //         onClick={()=>{
            //     if(props?.index === (props?.proposition.length - 1)) {props?.setIndex(0)} else {props?.setIndex((ps: number) => ps += 1);}
            // }}
            >
                <img className={"button-next-candidate-mobile__img"} src={"https://static.myshortlist.co/next-mobile.svg"} alt={"flash"} />

            </button>
            </a>
      }
    </>);
}