import NavbarBusinessPage from "./NavbarBusinessPage";
import Mission from "./Mission";
import React from "react";

export default (props: any) => {
return( <body className={"client-page__container"}>

    <NavbarBusinessPage categoryToDisplay={props?.categoryToDisplay}/>

    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>

    <div className={"subnav"}>
        <button className={`filter-business-page`} onClick={() => {props?.setCategoryToDisplay("inProgress")}}>
            <img className={"filter-business-page_img"} src={"https://static.myshortlist.co/in-progress.svg"}/>
            <div>En cours</div>

        </button>
        <button className={`filter-business-page`} onClick={() => {props?.setCategoryToDisplay("recruited")}}>
            <img className={"filter-business-page_img"} src={"https://static.myshortlist.co/recruited.svg"}/>
            Recruté
        </button>
        <button className={`filter-business-page`} onClick={() => {props?.setCategoryToDisplay("canceled")}}>
            <img className={"filter-business-page_img-canceled"} src={"https://static.myshortlist.co/canceled-v2.svg"}/>
            Annulé
        </button>
    </div>

    <br/> <br/>

    <div className={"client-page__missions"}>
        {props?.missions?.filter((item: any) => (item.blackBeltStatus === props?.categoryToDisplay)).map((item: any) => (
            <Mission item={item}/>))}

        {props?.missions?.filter((item: any) => (item.blackBeltStatus === props?.categoryToDisplay)).length===0 && <><br/><br/><br/><br/><br/></>}
    </div>

    <br/>
    <div className={"text--center"}>
        Pour nous confier un nouveau recrutement, contacter Dan via le mail dan@black-belt.io ou par téléphone au 01 84 80
        60 62.
    </div>
    <br/>
    <br/><br/>
    </body>
)
};