import React from "react";

export default (props: any) => {


    return (<>
        {/*<div className={"information--item-row"}>*/}
            <div className={"information--item"}>
                <img className={"information--item--img"} src={"https://static.myshortlist.co/location.svg"} alt={"location"}/>
                {props?.location} </div>
            <div className={"information--item"}>
                <img className={"information--item--img"} src={"https://static.myshortlist.co/graduate.svg"} alt={"graduate"}/>
                {props?.yearsOfExperience} ans d’expérience </div>
        {/*</div>*/}
        {/*<div className={"information--item-row"}>*/}
            <div className={"information--item"}>
                <img className={"information--item--img"} src={"https://static.myshortlist.co/euro-black.svg"} alt={"euro"}/>
                Salaire actuel {props?.currentSalary}k {props.currentVariableSalary && <> + {props.currentVariableSalary}K</> }</div>
            <div className={"information--item"}>
                <img className={"information--item--img"} src={"https://static.myshortlist.co/calendar-black.svg"} alt={"calendar"}/>
                {props?.availability==="0" && <>Dispo tout de suite</>}
                {props?.availability!=="0" && <>Dispo dans ± {props?.availability} mois</>}
            </div>
        {/*</div>*/}
    </>);
}