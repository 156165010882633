import React from "react";
export default (props: any) => {
    return(<div className={"new-account-container-mobile"}>
        <div className={"new-account-information"}>
            <img className={"margin3"} src={"https://static.myshortlist.co/gabrielle.svg"} alt={"gabrielle"}/>
            <div>
                <div className={"new-account-information-title"}> Gabrielle</div>
                <div className={"new-account-information-description"}> Un compte associé a cette adresse mail existe déjà
                </div>
            </div>
        </div>
    </div>);
}

