import React from "react";

export default (props:any) => {

    return (<div className={"feeling--container"}>
        <div className={`${props.pc ? "feeling__title":"feeling__title-on-mobile"}`}> Notre feeling </div>
        <div className={`${props.pc ? "section__content":"section__content-on-mobile"}`} style={{whiteSpace: "pre-wrap"}}>
            {props?.feeling}
        </div>
    </div>);
}