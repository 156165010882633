import React, {useContext} from "react";
import {devLog} from "../../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props:any) => {
    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};


    return (

            <button  className={"button-transparent"} onClick={async() => {
                try {

                    appContext.dispatchPanels({
                        type: "OPEN_WINDOW",
                        value: {
                            type: "shareCandidate",
                            data: {
                                token: props.token
                            }
                        }
                    });
                } catch(error) {
                    devLog(error);
                }
            }}>
                <img className={"social-network-item"} src={"https://static.myshortlist.co/share-v2.svg"}
                     alt={"partage"}/>
            </button>


    );
}