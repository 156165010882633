import React, {useContext, useEffect, useReducer, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import {Link} from "react-router-dom";
import axios from "axios";
import dataReducerBlackBeltTchats from "../../../publicSpacePages/componentsFlashcardV2Page/dataReducerBlackBeltTchats";
import dataReducerMails from "./dataReducerMails";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    const [windowStatus, setWindowStatus] = useState<'loading' | 'error' | 'forbidden' | 'loaded' | 'ok'>("loading");

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [emails, dispatchMails]: [any, Function] = useReducer(dataReducerMails, []);
    const missionId = appContext.appPanels.window.windowOptions.data?.missionId;
    console.log("emails", emails);

    useEffect(() => {
        (async () => {
            try {
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/mails/for/mission`,
                    {
                        headers: {missionid: missionId},
                        withCredentials: true
                    })).data;
                setWindowStatus("ok")
                dispatchMails({type: "INITIALIZE_DATA", value: data ?? []});

            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    return (<WindowLayout size={"big"} pageStatus={windowStatus} windowTitle={"Ajoutez les collaborateurs qui souhaitent être notifiés des réponses de Black-Belt sur le tchat pour ce poste"}>

        {(!emails || emails.length === 0) && <div className={"text--center text--grey"}>
            Aucun email n'est enregitré pour ce poste
        </div>}

        {emails?.map((item: any) => {
            return (
                <>
                    <div className={`client-page__candidate`}>
                        <div className={"client-page__candidate__block__name"}>
                            <div className={"client-page__candidate__name"}>{item.fullName?.toUpperCase() ?? ""}</div>
                            <div>{item.email}</div>
                        </div>
                        <br/>
                        <button className={"client-page__delete-button"}
                                onClick={async () => {
                                    const {status, data} =
                                        (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/delete/mail/for/mission`,
                                            {
                                                mailId:item._id,
                                                missionId: missionId
                                            }
                                            , {
                                                withCredentials: true,
                                            })).data;

                                    if (status !== "ok") {
                                        console.log(status);
                                        return;
                                    }
                                    dispatchMails({type: "DELETE_MAIL", value: {mail: item.email}});
                                    setName("");
                                    setMail("");
                                }}>
                            <img className={"client-page__delete-button-img"}
                                 src={"https://static.myshortlist.co/delete-blue.svg"}/>
                        </button>

                    </div>

                </>);
        })}

        <br/>
        <div className={"share-candidate"}>
            <input className={"tchat-input"}
                   placeholder={"Nom"}
                   value={name}
                   onChange={(e) => {
                       setName(e.target.value);
                   }}/>

            <input className={"tchat-input"}
                   placeholder={"Email"}
                   value={mail}
                   onChange={(e) => {
                       setMail(e.target.value);
                   }}/>

            <button className={"button-tchat"} disabled={!name || !mail}
                    onClick={async () => {

                        const {status, data} =
                            (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/new/mail/for/mission`,
                                {
                                    fullName: name,
                                    email: mail,
                                    missionId: missionId
                                }
                                , {
                                    withCredentials: true,
                                })).data;
                        console.log("data", data);

                        if (status !== "ok") {
                            console.log(status);
                            return;
                        }
                        dispatchMails({type: "ADD_MAIL", value: {fullName: name, email: mail}});
                        setName("");
                        setMail("");
                    }}>Ajouter
            </button>
        </div>


    </WindowLayout>);
}