import React from "react";
export default (props: any) => {

    const date=props?.date?.split("T")[0];
    const day=date?.split("-")[2];
    const month=date?.split("-")[1];
    const year=date?.split("-")[0];

    return (<>
        <div className={`${props.pc ? "flashcard__title":"flashcard__title-on-mobile"}`}>
            <div className={`${props.pc ? "flashcard__title__img":"flashcard__title__img-on-mobile"}`}>
                {props?.tas?.toLowerCase().trim() === "salomon" &&
                <img className={`${props.pc ? "flashcard__title__img":"flashcard__title__img-on-mobile"}`}
                     src={"https://static.myshortlist.co/spt.jpg"} alt={"salomon"}/>}
                {props?.tas?.toLowerCase().trim() === "jeremy" &&
                <img className={`${props.pc ? "flashcard__title__img":"flashcard__title__img-on-mobile"}`}
                     src={"https://static.myshortlist.co/jeremy.jpg"} alt={"jeremy"}/>}
                {props?.tas?.toLowerCase().trim() === "jessica" &&
                <img className={`${props.pc ? "flashcard__title__img":"flashcard__title__img-on-mobile"}`}
                     src={"https://static.myshortlist.co/jessica.jpg"} alt={"jesssica"}/>}
                {(props?.tas?.toLowerCase().trim() === "lea" || props?.tas?.toLowerCase().trim() === "léa" ) &&
                    <img className={`${props.pc ? "flashcard__title__img":"flashcard__title__img-on-mobile"}`}
                         src={"https://static.myshortlist.co/lea3.jpg"} alt={"léa"}/>}

            </div>
            <div>
                <div className={"flashcard"}> Flashcard</div>
                <div className={"flashcard__content"}>
                    Interviewé le <span className={"flashcard__content-date"}> {day}.{month}.{year} </span> par {props?.tas} </div>
            </div>

        </div>
        <div className={`${props.pc ? "flashcard--navbar": "flashcard--navbar-on-mobile"}`}>
            <div className={"flashcard--navbar__item"}>
                <div className={"flashcard--navbar__item__circle feeling"}></div>
                <a href={"#navbar"}><div className={""}> Notre feeling</div></a>
            </div>

            <div className={"flashcard--navbar__item blue"}>
                <div className={"flashcard--navbar__item__circle wants"}></div>
                <a href={"#feeling"}><div className={""}> Ses envies</div></a>
            </div>

            <div className={"flashcard--navbar__item"}>
                <div className={"flashcard--navbar__item__circle matching"}></div>
                <a href={"#matching"}><div className={""}> Matching score</div></a>
            </div>

            <div className={"flashcard--navbar__item"}>
                <div className={"flashcard--navbar__item__circle experience"}></div>
                <a href={"#experience"}><div className={""}> Ses expériences</div></a>
            </div>
        </div>

    </>);
}