import React from "react";


export default (props: any) => {

    return (<>
        <div className={"client-page-navbar"}>
            <div className={"client-page-navbar-left"}>
                <img className={""} src={"https://static.myshortlist.co/logo-black.svg"} alt={"logo"}/>
            </div>

            <div>
                { props?.categoryToDisplay==="inProgress"&& <h1 className={"client-page__title"}> Postes en cours</h1>}
                { props?.categoryToDisplay==="recruited"&& <h1 className={"client-page__title"}> Postes recruté</h1>}
                { props?.categoryToDisplay==="canceled"&& <h1 className={"client-page__title"}> Postes annulé</h1>}
            </div>

            <div className={"client-page-navbar-right"}> </div>


        </div>
    </>);
}