import {initialSession} from "../state/initialSession";
import {IAppSession} from "../state/IAppSession";

export {sessionReducer};

export type TSessionReducers =
  "SET_SESSION" |
  "LOGOUT" |
  "SET_SESSION_ERROR";

export interface ISetSessionAction {
  type: TSessionReducers;
  value: IAppSession | string | null;
}

const sessionReducer = (state: IAppSession, action: ISetSessionAction): IAppSession => {

  switch(action.type) {

    case 'LOGOUT':
      return ({
        ...initialSession,
        app: {
          ...initialSession.app,
          appStatus: 'loaded',
          userStatus: 'unidentified',
        },
      });

    case 'SET_SESSION':
      return ({
        ...state,
        ...action.value as IAppSession,
      });
  
    case "SET_SESSION_ERROR":
      return state;
  
    default:
      throw new Error(`Type ${action.type} is not defined in sessionReducer`);
  }

}