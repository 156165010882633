import React from "react";

export default function CloseButton(props: { eventOnClick: Function, title?: string },) {

    return (
        <section className={"text--right"}>
            <button className={"button-transparent"} onClick={() => props.eventOnClick()} title={!!props.title ? props.title : ""}>
                <img className={""} alt={"close"} src={"https://static.myshortlist.co/btn-close.svg"}/>
            </button>
        </section>
    );
}