type TState = {
    [key: string]: any,
};

export default (previousState: TState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return ({...action.value});

        case "UPDATE_VALUE":
            return {
                ...previousState,
                [action.value.key]: action.value.value
            }

        case 'UPDATE_ARRAY_ADD':
            return ({
                ...previousState,
                [action.value.key]: [...previousState[action.value.key], action.value.value]
            });

        case 'UPDATE_ARRAY_REMOVE':
            return ({
                ...previousState,
                [action.value.key]: previousState[action.value.key].filter((item: any) => item !== action.value.value)
            });

        default:
            return previousState;
    }

}