import React from "react";

export default (props: any) => {

    return (<>

        <div className={"navbar-new-account"}>
            <div> <button className={"navbar-new-account-button"} onClick={()=>{}}>Vos informations </button></div>
            <div> <button className={"navbar-new-account-button"} onClick={()=>{}}>Votre entreprise </button></div>
            <div> <button className={"navbar-new-account-button"} onClick={()=>{}}>Démonstration </button></div>

        </div>

        {props.step===1 && <div className={"navbar-bottom-new-account-informations"}> </div>}
        {props.step===2 && <div className={"navbar-bottom-new-account-business"}> </div>}
        {props.step===3 && <div className={"navbar-bottom-new-account-demonstration"}> </div>}

    </>);
}