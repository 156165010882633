import React, {useContext, useState} from "react";
import {AppContext, IAppContext} from "../../../componentsApp/context/AppContext";
import WindowLayout from "../../layouts/WindowLayout";
import axios from "axios";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
    const token = appContext.appPanels.window.windowOptions.data?.token;
    console.log("token", token);
    const [mail, setMail] = useState("");

    const [sent, setSent] = useState(false);

    if(sent) {
        return (<WindowLayout size={"small"} pageStatus={"ok"} windowTitle={"Partage du candidat"}>
            Lien envoyé avec succès.
        </WindowLayout>);
    }

    return (<WindowLayout size={"small"} pageStatus={"ok"} windowTitle={"Partage du candidat"}>

        <div className={"share-candidate"}>
            <input className={"tchat-input"}
                   placeholder={"Email du destinataire"}
                   value={mail}
                   onChange={(e) => {
                       setMail(e.target.value);
                   }}/>

            <button className={"button-tchat"}
                    onClick={async() => {

                        const {status, data} =
                            (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/share/flashcard`,
                                {
                                    token: token, mail: mail,
                                }
                                , {
                                    withCredentials: true,
                                })).data;

                        if(status !== "ok") {
                            console.log(status);
                        }
                        setSent(true);
                    }}>Envoyer
            </button>
        </div>
    </WindowLayout>);
}