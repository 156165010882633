import React from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import {useContext} from "react";
import WindowError from "./componentsWindow/WindowError";
import WindowCandidatesOfMission from "./componentsWindow/WindowCandidatesOfMission";
import WindowShareCandidate from "./componentsWindow/WindowShareCandidate";
import WindowMailsOfMission from "./componentsWindow/WindowMailsOfMission";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;

    switch(appContext.appPanels.window.windowOptions.type) {

        case "windowAbc":
            return <WindowError />
            break;

        case "candidatesOfMission":
            return (<WindowCandidatesOfMission/>);
            break;

        case "mailsOfMission":
            return (<WindowMailsOfMission/>);
            break;



        case "shareCandidate":
            return (<WindowShareCandidate/>);

        default:
            return <WindowError />
    }


}