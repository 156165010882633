import React from "react";

export default (props: any) => {

    return (<>

        {props.step === 1 && <div>
            <button className={"navbar-new-account-button-mobile"} onClick={() => {
            }}>Vos informations
            </button>
        </div>}
        {props.step === 2 && <div>
            <button className={"navbar-new-account-button-mobile"} onClick={() => {
            }}>Votre entreprise
            </button>
        </div>}
        {props.step === 3 && <div>
            <button className={"navbar-new-account-button-mobile"} onClick={() => {
            }}>Démonstration
            </button>
        </div>}


        {props.step === 1 && <div className={"navbar-bottom-new-account-informations-mobile"}></div>}
        {props.step === 2 && <div className={"navbar-bottom-new-account-business-mobile"}></div>}
        {props.step === 3 && <div className={"navbar-bottom-new-account-demonstration-mobile"}></div>}

    </>);
}