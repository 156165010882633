import {IAppSession} from "../state/IAppSession";

export {getSession};

const getSession = async(): Promise<IAppSession> => {
  try {

    // fetch data here

    return ({
      app: {
        appStatus: 'loaded',
        userStatus: 'unidentified',
        mode: 'local',
      }
    });

  } catch(error) {
    console.error(error);
    return {app: {
        appStatus: 'error',
        userStatus: 'unidentified',
        mode: process.env.REACT_APP_MODE as 'local' | 'production',
      }};
  }
}
