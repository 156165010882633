import NavbarBusinessPage from "./NavbarBusinessPage";
import React, {useContext, useState} from "react";
import Spaces from "../../sharedComponents/components/Spaces";
import axios from "axios";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: any) => {
    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
    const [password, setPassword]=useState("");
    const [wrongPassword, setWrongPassword]=useState(false);

    return (<body className={"client-page__container"}>

        <NavbarBusinessPage categoryToDisplay={props?.categoryToDisplay}/>
        <Spaces n={10}/>


        <div className={"center"}> <img src={"https://static.myshortlist.co/logoMedadomWithoutBackground.png"}/></div>
        <div className={"text--center"}>
            Bienvenue sur votre dashboard.
        </div>
        <div className={"text--center"}>
            Accéder à toutes vos missions!
        </div>
        <br/>
        <div className={"center"}>
        <input className={`${props.pc ? "tchat-input" : "tchat-input-tel"}`} placeholder={"Mot de passe"} value={password}
               onChange={(e) => {
                   setPassword(e.target.value);
               }}/>

            <button className={`${props.pc ? "button-tchat" : "button-tchat-tel"}`} disabled={!password} onClick={async () => {
                const {
                    status,
                    data
                } = (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/bb/dashboard/login`,
                    {
                        token: props.token,
                        password: password,
                    }
                    , {
                        timeout: 5000,
                        withCredentials: true,
                    })).data;
                console.log("status", status);

                if (status !== "ok") {
                    setWrongPassword(true);
                    return false;
                }

                window.sessionStorage.setItem('password', password);
                setPassword("");
                props.setStep("dashboard")

            }}>Connexion
            </button>
        </div>
        <br/>
        {wrongPassword && <div className={"text--center new-account-information-item-input_warning"}> Le mot de passe est incorrect</div>}

        <br/>
        <br/><br/>
        </body>
    )
};