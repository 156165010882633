import React, {useContext, useEffect, useState} from "react";
import {devLog} from "../../utils/devLog/devLog";
import {notification} from "../../utils/notification";
import axios from "axios";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import PageToApplyStep1 from "./componentsPageToApply/PageToApplyStep1";
import PageToApplyStep2 from "./componentsPageToApply/PageToApplyStep2";
import PageToApplyStep1Mobile from "./componentsPageToApply/PageToApplyStep1Mobile";
import PageToApplyStep2Mobile from "./componentsPageToApply/PageToApplyStep2Mobile";
import {isEmailValid} from "../../utils/typeGuards";
import queryString from 'query-string';


export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;
    const slugname = props.match.params.slugname;
    const slugnumber = props.match.params.slugnumber;
    const rank = props.match.params.rank;
    const webOrigin = queryString.parse(props.location.search).o as string; // origin tracker
    const webAmbassador = queryString.parse(props.location.search).a as string; // ambassador tracker
    const webFromEmail = queryString.parse(props.location.search).f as string; // coming back from indeed tracker
    const kid = queryString.parse(props.location.search).kid;

    const [mission, setMission]: any = useState();
    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");

    const [name, setName]: any = useState();
    const [firstName, setFirstName]: any = useState();
    const [mail, setMail]: any = useState();
    const [phone, setPhone]: any = useState();
    const [linkedin, setLinkedin]: any = useState();
    const [salaryWanted, setSalaryWanted]: any = useState();
    const [candidacyStatus, setCandidacyStatus]: any = useState();


    const isFirstNameOk: boolean = firstName;
    const isLastNameOk: boolean = name;
    const isEmailOk: boolean = mail && isEmailValid(mail);
    const isPhoneOk: boolean = phone;
    const isLinkedinOk: boolean = linkedin?.includes("/in/");
    const isSalaryOk: boolean = salaryWanted;

    const isButtonStatusGood = isFirstNameOk && isLastNameOk && isEmailOk && isPhoneOk && isLinkedinOk && isSalaryOk;

    const [step, setStep]: any = useState(1);


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/ent/mfc/get/mission/form`, {
                    headers: {
                        slugname: slugname,
                        slugnumber: slugnumber,
                        rank: rank,
                        version:"v2",
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    setFetchStatus("ok");
                }
                setFetchStatus("ok");
                devLog(data);
                setMission(data);


            } catch (error) {
                devLog(error);
            }
        })();
    }, []);


    const sendCandidacy = async () => {
        try {


            const dataToSend: any = {
                blackBelt: true,
                missionId: mission._id,
                firstName: firstName,
                lastName: name,
                email: mail,
                phone: phone,
                linkedin: linkedin,
                webOrigin: webOrigin,
                webAmbassador:webAmbassador,
                webFromEmail:webFromEmail,
                defaultAnswers: {salaryMin: salaryWanted},
                kid:kid,
                version:"v2",
            }
            const {
                status,
                data
            } = await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/mat/ca/candidacy`, dataToSend, {
                withCredentials: true,
                timeout: 10000,
            });

            devLog("sendCandidacy", status, data);
            setCandidacyStatus(data.status);
            setStep(2);


            if (data.status !== "ok") {
                return notification(appContext.dispatchPanels, "Une erreur est survenue", "error");

            }


        } catch (error) {
            notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
            devLog(error);
        }
    }


    return (<PageLayout pageStatus={fetchStatus}>

        <div className={"hide-on-smartphones"}>
            {step === 1 && <PageToApplyStep1 mission={mission}
                                             name={name}
                                             setName={setName}
                                             firstName={firstName}
                                             setFirstName={setFirstName}
                                             mail={mail}
                                             setMail={setMail}
                                             phone={phone}
                                             setPhone={setPhone}
                                             linkedin={linkedin}
                                             setLinkedin={setLinkedin}
                                             salaryWanted={salaryWanted}
                                             setSalaryWanted={setSalaryWanted}
                                             isFirstNameOk={isFirstNameOk}
                                             isLastNameOk={isLastNameOk}
                                             isEmailOk={isEmailOk}
                                             isPhoneOk={isPhoneOk}
                                             isLinkedinOk={isLinkedinOk}
                                             isSalaryOk={isSalaryOk}
                                             isButtonStatusGood={isButtonStatusGood}
                                             sendCandidacy={sendCandidacy}
            />}

            {step === 2 && <PageToApplyStep2 mission={mission} candidacyStatus={candidacyStatus}/>}
        </div>


        <div className={"hide-on-tablets-and-computers"}>
            {step === 1 && <PageToApplyStep1Mobile mission={mission}
                                                   name={name}
                                                   setName={setName}
                                                   firstName={firstName}
                                                   setFirstName={setFirstName}
                                                   mail={mail}
                                                   setMail={setMail}
                                                   phone={phone}
                                                   setPhone={setPhone}
                                                   linkedin={linkedin}
                                                   setLinkedin={setLinkedin}
                                                   salaryWanted={salaryWanted}
                                                   setSalaryWanted={setSalaryWanted}
                                                   isFirstNameOk={isFirstNameOk}
                                                   isLastNameOk={isLastNameOk}
                                                   isEmailOk={isEmailOk}
                                                   isPhoneOk={isPhoneOk}
                                                   isLinkedinOk={isLinkedinOk}
                                                   isSalaryOk={isSalaryOk}
                                                   isButtonStatusGood={isButtonStatusGood}
                                                   sendCandidacy={sendCandidacy}
            />}

            {step === 2 && <PageToApplyStep2Mobile mission={mission} candidacyStatus={candidacyStatus}/>}
        </div>


    </PageLayout>)
}

