import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ContactOnMobile from "./ContactOnMobile";


export default (props: any) => {

    function getNextToken(proposition:any, index:number){
        if (index === (proposition?.length - 1)){
            return proposition[0]?._id;
        }
        else {
            return proposition[index + 1]?._id;
        }
    }
    return (<>

        {!props.anonymous && <div>
            <div className={"img-gradient-on-mobile"}>
                <img className={""} src={props?.avatarUrl}/>
            </div>
            <Link to={`/video/${props.token}`}>
                <img className={"play"} src={"https://static.myshortlist.co/play.svg"}/>
            </Link>
        </div>
        }

        {props.anonymous && <>
            <div className={"img-gradient-on-mobile"}>
                <img className={""} src={props?.avatarUrl}/>
            </div>
            <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"play"} src={"https://static.myshortlist.co/play.svg"}/>
            </Link>

        </>}

        {!props.anonymous &&
            <a href={`https://app.black-belt.io/business/${props?.blackBeltBusinessClientId}`}>
                <button className={"button-all-jobs-tel"}> Dashboard
                    <img className={"dashboard-mobile"} src={"https://static.myshortlist.co/dashboard-blue.svg"}/>
                </button>
            </a>
        }

        {!props.anonymous && props.proposition?.length!==1 &&
            <a href={`https://app.black-belt.io/card/${getNextToken(props?.proposition,props?.index)}`}>
                <button className={"button-next-tel"}> Candidat Suivant
                    <img className={"dashboard-mobile"} src={"https://static.myshortlist.co/arrow-going-up-blue.svg"}/>
                </button>
            </a>
        }

        <img className={"logo-mobile"} src={"https://static.myshortlist.co/logo-mobile.svg"} alt={"logo"}/>

        {props.anonymous && <ContactOnMobile anonymous={props?.anonymous} token={props?.token} candidate={props?.candidate} search={props.search}/>}


    </>);
}