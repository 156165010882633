import React, {useContext, useEffect, useState} from "react";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import BusinessPagePc from "./componentsBusinessPage/BusinessPagePc";
import BusinessPageMobile from "./componentsBusinessPage/BusinessPageMobile";
import LoginBusinessPage from "./componentsBusinessPage/LoginBusinessPage";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
    const [loading, setLoading] = useState<"loading" | "error" | "forbidden" | "loaded">('loading');
    const token: string = props.match.params.token || "";
    const [missions, setMissions] = useState([]);
    const [categoryToDisplay, setCategoryToDisplay] = useState<'inProgress' | 'recruited' | 'canceled'>('inProgress');
    const [step, setStep] = useState("");

    useEffect(() => {
        if (token === "6225ab2405e7a066d164be06") setStep("login");
        else setStep("dashboard");
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (token === "6225ab2405e7a066d164be06") {
                    const password = window.sessionStorage.getItem('password');
                    if (password) {
                        const {
                            status,
                            data
                        } = (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/bb/dashboard/login`,
                            {
                                token: token,
                                password: password,
                            }
                            , {
                                timeout: 5000,
                                withCredentials: true,
                            })).data;
                        if (status === 'ok') {
                            setStep("dashboard");
                        }
                        else{
                            setStep("login");
                        }
                    } else {
                        setStep("login");
                    }
                }
                else{
                    setStep("dashboard");
                }
            } catch (error) {
                alert(error.message);
            }
        })()
    }, []);


    useEffect(() => {

        let timer: any;

        async function getData() {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/business/page`,
                    {
                        headers: {token: token},
                        withCredentials: true
                    })).data;
                setMissions(data.missions);
                setLoading('loaded');
            } catch (error) {
                console.log(error);
            }
        }

        (async function () {
            try {
                await getData();
                timer = setInterval(() => {
                    getData();
                }, 3 * 60 * 1000)
            } catch (error) {
                devLog(error);
            }
        })();

        return () => clearInterval(timer)
    }, []);

    return (<PageLayout pageStatus={loading}>

        {step === "login" && <>
            <div className={"hide-on-smartphones"}>
                <LoginBusinessPage token={token}
                                   setStep={setStep}
                                   pc={true}
                />
            </div>
            <div className={"hide-on-tablets-and-computers"}>
                <LoginBusinessPage token={token}
                                   setStep={setStep}
                                   pc={false}/>
            </div>

        </>}

        {step === "dashboard" && <>
            <div className={"hide-on-smartphones"}>
                <BusinessPagePc setCategoryToDisplay={setCategoryToDisplay}
                                categoryToDisplay={categoryToDisplay}
                                missions={missions}/>
            </div>

            <div className={"hide-on-tablets-and-computers"}>
                <BusinessPageMobile setCategoryToDisplay={setCategoryToDisplay}
                                    categoryToDisplay={categoryToDisplay}
                                    missions={missions}/>
            </div>
        </>}
    </PageLayout>);

}