import React from "react";
import {Link} from "react-router-dom";
import axios from "axios";


export default (props:any) => {


    async function selectCandidate() {
        try {

            props?.setSelected((ps: any) => ([...ps,props?.candidacyId]));
            const {status,data}=(await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/flashcard/select/candidate`,
                {
                    token:props.token,
                    candidate:props?.candidate,
                    candidacyId:props?.candidacyId,
                },{
                    withCredentials: true,
                })).data;


        } catch (error) {
            throw error;
        }
    }

    async function deselectCandidate() {
        try {
            props?.setSelected((ps: any) => (ps.filter((item: any) => item !== props?.candidacyId)));
            const {status,data}=(await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/flashcard/deselect/candidate`,
                {
                    token:props.token,
                    selected:{...props?.candidate,candidacyId:props?.candidacyId},
                },{
                    withCredentials: true,
                })).data;


        } catch (error) {
            throw error;
        }
    }



    return (<>

        {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
        <button  className={`${props?.navbar ? "button-select-candidate-navbar" :"button-select-candidate"}`}>
            {props?.firstName} m'interesse

            <img className={"button-select-candidate__img-big"} src={"https://static.myshortlist.co/flash-white.svg"} alt={"flash"} />

        </button>
        </Link>}



        {!props.anonymous && !props?.selected?.includes(props?.candidacyId) && <button  className={`${props?.navbar ? "button-select-candidate-navbar" :"button-select-candidate"}`}
        onClick={()=>{selectCandidate()}}>
            {props?.firstName} m'interesse

            <img className={"button-select-candidate__img-big"} src={"https://static.myshortlist.co/flash-white.svg"} alt={"flash"} />

        </button>}

        {!props.anonymous && props?.selected.includes(props?.candidacyId) && <button  className={`${props?.navbar ? "button-select-candidate-navbar-height" :"button-select-candidate"}`}
                                                         onClick={()=>{deselectCandidate()}}>
            Vous avez choisi {props?.firstName} <br/>
            Changer mon choix
            <img className={"button-select-candidate__img"} src={"https://static.myshortlist.co/flash-white.svg"} alt={"flash"} />

        </button>}




    </>);
}