import React from "react";

export default (props: any) => {

    return (<>
        <div className={"name-position-match"}>
            <div>
                <div className={"name-position-match--title"}>{props?.firstName}  {props?.lastName?.toUpperCase().substring(0, 1) }.</div>
                <div className={"position-wanted"}> {props?.job}</div>
            </div>
            <div className={"match"}> Match {props.matching}% </div>
        </div>
    </>);
}