import React, {useEffect, useState} from "react";
import {
    Radar, RadarChart, PolarGrid,
    PolarAngleAxis, PolarRadiusAxis
} from 'recharts';


export default (props: any) => {

    const [displayInformation, setDisplayInformation] = useState(false);

    const data = [
        {name: 'Culture', x: props?.culture},
        {name: 'Soft skills', x: props?.softSkills},
        {name: 'Hard skills', x: props?.hardSkills},
        {name: 'Autonomie', x: props?.autonomy},
        {name: 'Créativité', x: props?.creativity},

    ];

    return (<div className={""}>




        <div className={"section__title-on-mobile"}> Matching Score
            <button className={"button-matching-score"} onClick={() => {
                setDisplayInformation(prevState => (!prevState))
            }}>
                <img className={"matching-score__img"} src={"https://static.myshortlist.co/information.svg"}
                     alt={"information"}/>
            </button>

            {displayInformation && <div className={"matching-score__arrow-description-mobile"}><div className={"matching-score__description-column"}>
                <div className={"matching-score__description-mobile"}> Technologie avancée de machine learning permettant de
                    mieux prédire la réussite d’un employé
                    dans l’entreprise en prenant en compte une multitudes de données.
                </div>
                <div className="arrow-down"> </div>
            </div>
            </div>
            }
        </div>




        <div className={"matching-score-navbar"}>
            <div className={"matching-score-title"}> {props.company }</div>
            <div className={"matching-score-title"}> {props?.matching}%</div>
            <div className={"matching-score-title column"}>
                {props?.avatarUrl && <div>
                    <img className={"avatar__img"} src={props?.avatarUrl}
                         alt={"avatar__img"}/>
                </div>}
                {!props?.avatarUrl && <div className={"avatar-on-matching-score"}>
                </div>
                }
                {props?.firstName}
            </div>
        </div>

        <div onClick={()=>{ if(displayInformation){setDisplayInformation(false)}}} className={"matching-score-container"}>
            <div className={"matching-score-content"}>

                <br/>

                <RadarChart height={160} width={300}
                            outerRadius="80%" data={data}>
                    <PolarGrid/>
                    <PolarAngleAxis dataKey={`name`}/>
                    {/*<PolarRadiusAxis />*/}
                    <Radar dataKey="x" stroke="#063EF9"
                           fill="#063EF9" fillOpacity={0.5}/>
                </RadarChart>

                <br/>
            </div>
        </div>


        <br/>

    </div>);
}


