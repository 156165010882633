import React from "react";
import WindowLayout from "../../layouts/WindowLayout";


export default (props: any) => {

    return(<WindowLayout pageStatus={"ok"}>

        Cette fenêtre n'existe pas

    </WindowLayout>)
}