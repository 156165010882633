import React from "react";

export default (props: any) => {


    return (<>
        <div className={"row"}>
        <div className={"information--item-on-mobile"}>
            <img className={"information--item--img"} src={"https://static.myshortlist.co/location.svg"} alt={"location"}/>
            {props?.location}
        </div>
        <div className={"information--item-on-mobile"}>
            <img className={"information--item--img"} src={"https://static.myshortlist.co/graduate.svg"} alt={"graduate"}/>
            {props?.yearsOfExperience} ans d’expérience </div>
        </div>
        <div className={"row"}>
        <div className={"information--item-on-mobile"}>
            <img className={"information--item--img"} src={"https://static.myshortlist.co/euro-black.svg"} alt={"euro"}/>
            Salaire actuel ± {props?.currentSalary}k</div>
        <div className={"information--item-on-mobile"}>
            <img className={"information--item--img"} src={"https://static.myshortlist.co/calendar-black.svg"} alt={"calendar"}/>
            Disponible dans ± {props?.availability} mois </div>
        </div>
    </>);
}