//
//
// import React, {useContext} from "react";
// import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
// import CloseButton from "../components/CloseButton";
// import {AppLevelLoading} from "../../publicSpacePages/AppLevel/AppLevelLoading";
//
// interface IWindowLayout {
//     windowTitle?: string,
//     children: any,
//     pageStatus?: 'loading' | 'error' | 'forbidden' | 'loaded' | 'ok',
//     size?:string;
// }
//
// export default (props: IWindowLayout) => {
//
//     const status: 'loading' | 'error' | 'forbidden' | 'loaded' | 'ok'= props.pageStatus ? props.pageStatus : "ok";
//
//     if(status === "loading") {
//         return (<WindowLayout {...props}>
//           <AppLevelLoading />
//         </WindowLayout>);
//     }
//
//     if(status === "error" || status === "forbidden") {
//         return (<WindowLayout {...props}>
//             return(<>Error</>);
//         </WindowLayout>);
//     }
//
//     return (
//         <WindowLayout {...props}>
//             {props.children}
//         </WindowLayout>
//     );
// }
//
// function WindowLayout(props: any) {
//
//     const appContext: IAppContext = useContext(AppContext) as IAppContext;
//     let size = !!props.size ? props.size : 'small';
//
//     return (
//         <WindowBlankLayout>
//             <section
//                 className={`window window--appear ${size === 'big' ? 'window--big' : ''}`}
//                 onClick={(e) => {e.stopPropagation();}}>
//             <article className={"window__header"}>
//                 <span className={"window__header__logo"}></span>
//                 <CloseButton eventOnClick={() => {
//                         appContext.dispatchPanels({type: "CLOSE_WINDOW", value: null});
//                 }}/>
//             </article>
//
//             <article className={"window__title"}>
//                 {props.windowTitle}
//             </article>
//
//             {props.children}
//             </section>
//         </WindowBlankLayout>);
//
// }
//
// function WindowBlankLayout(props: {status?: "ok" | "loading" | "error", children: any }) {
//
//     const appContext: IAppContext = useContext(AppContext) as IAppContext;
//
//     return (
//         <>
//             <div
//                 className={`window-container`}
//                 onClick={(e) => {
//                     e.preventDefault();
//                 }}>
//                 <section
//                     className={`window window--appear `}
//                     onClick={(e) => {e.stopPropagation();}}>
//                     <article className={"animation-fade-in--faster"}>
//
//                         {props.children}
//
//                     </article>
//                 </section>
//             </div>
//         </>
//     );
// }
//
//


import React, {useContext} from "react";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";
import CloseButton from "../components/CloseButton";

interface IWindowLayout {
    windowTitle?: string,
    size?: 'big' | 'small' | 'bigger',
    children?: any,
    pageStatus?: 'loading' | 'error' | 'forbidden' | 'loaded' | 'ok'
}

export default (props: IWindowLayout) => {

    const status: 'loading' | 'error' | 'forbidden' | 'loaded' | 'ok' = props.pageStatus ? props.pageStatus : "ok";

    if(status === "loading") {
        return (<WindowLayout {...props}>
            <div> Chargement... </div>
        </WindowLayout>);
    }

    if(status === "error" || status==="forbidden") {
        return (<WindowLayout {...props}>
            Une erreur est survenue
        </WindowLayout>);
    }

    return (
        <WindowLayout {...props}>
            {props.children}
        </WindowLayout>
    );
}

function WindowLayout(props: any) {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;

    let size = !!props.size ? props.size : 'small';
    return (
        <>
            <div
                className={`window-container window-container--appear `}
                onClick={(e) => {
                    e.preventDefault();
                }}>
                <section
                    className={` window--appear  ${size === 'big' ? 'window--big' : 'window'} ${size === 'bigger' ? 'window--bigger' : ''}`}
                    onClick={(e) => {e.stopPropagation();}}>

                    <article className={"animation-fade-in--faster"}>

                        <article className={"window__header"}>
                            <span className={"window__header__logo"}></span>
                            <CloseButton eventOnClick={() => {
                                appContext.dispatchPanels({type: "CLOSE_WINDOW", value: null});
                            }}/>
                        </article>

                        <article className={"window__title"}>
                            {props.windowTitle}
                        </article>

                        <article className={"window__content"}>
                            {props.children}
                        </article>


                    </article>

                </section>
            </div>
        </>
    );

}




