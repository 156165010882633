import React, {useContext} from "react";
import {format} from "date-fns";
import {devLog} from "../../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: {
    item: any;
}) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};

    return (<div className={`client-page__mission-mobile`}>

                <div className={"client-page__mission__item"}><span
                    className={"client-page__date__job-title"}>{props.item.positionWanted}</span>
                </div>


                <div className={"client-page__mission__avatars"}>
                    {props.item?.avatars && props.item?.avatars?.length !== 0 && props.item?.avatars?.map((url: string) => (
                        <img src={url}/>))}
                </div>

           <br/>
        <div className={"client-page__mission__items-mobile"}>
            <div className={"client-page__mission__item"}>
                <div className={"client-page__mission__item--title"}>
                    <img className={"client-page__mission__item--title--img"} src={"https://static.myshortlist.co/date.svg"}/>
                    Date </div>
                <div className={"client-page__mission__item--content"}>{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd/MM/yyyy") : ""}</div>
            </div>
            <div className={"client-page__mission__item"}>
                <div className={"client-page__mission__item--title"}>
                    <img className={"client-page__mission__item--title--img"} src={"https://static.myshortlist.co/dollar.svg"}/>
                    Package</div>
                <div className={"client-page__mission__item--content"}>{props.item.salaryPackage ?? "--"}</div>
            </div>
            <div className={"client-page__mission__item"}>
                <div className={"client-page__mission__item--title"}>
                    <img className={"client-page__mission__item--title--img"} src={"https://static.myshortlist.co/status.svg"}/>
                    Statut</div>
                {props.item.blackBeltStatus === 'inProgress' &&
                    <div className={"client-page__mission__item--content"} >EN COURS</div>}
                {props.item.blackBeltStatus === 'recruited' &&
                    <div className={"client-page__mission__item--content"} >RECRUTÉ</div>}
                {props.item.blackBeltStatus === 'canceled' &&
                    <div className={"client-page__mission__item--content"}>ANNULÉ</div>}
            </div>
        </div>
        <div className={"client-page__mission__items-mobile"}>


        <button className={"client-page__btn"} onClick={async () => {
                try {

                    appContext.dispatchPanels({
                        type: "OPEN_WINDOW",
                        value: {
                            type: "candidatesOfMission",
                            data: {
                                missionId: props.item.id
                            }
                        }
                    });
                } catch (error) {
                    devLog(error);
                }
            }}>  {props.item.nCandidates >1 && <span>Candidats</span>}
                {props.item.nCandidates <=1 && <span>Candidat</span>}
            </button>

        </div>
    </div>);
}