import { Widget } from '@typeform/embed-react'
import React from "react";

export default (props: any) => {

    const missionref = props.match.params.missionRef;
    return (<>
        <Widget id="jFdVs4At" style={{ width: '100vw' , height :'100vh'}} className="my-form"
                hidden={{
                    missionref: `${missionref}`,
                }}
             />
    </>);
}
