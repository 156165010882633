import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import {devLog} from "../../../utils/devLog/devLog";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";


export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext || {};
    const[displayPhone,setDisplayPhone]=useState(false);

    function getCvUrl(candidate: any) {
        if (candidate?.cvUrls?.length !== 0) {
            return (candidate?.cvUrls ?? [])[(candidate?.cvUrls?.length ?? 1) - 1] ?? "";
        }
        if (candidate.cvUrl) {
            return candidate?.cvUrl ?? "";
        }
        return "";
    }

    return(<>
        <div className={"contact-on-mobile"}>

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img-mobile"} src={"https://static.myshortlist.co/mail-blue.svg"}
                     alt={"resume"}/>
            </Link>}

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img-mobile"} src={"https://static.myshortlist.co/phone-blue.svg"}
                     alt={"phone"}/>
            </Link>}

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img-mobile"} src={"https://static.myshortlist.co/linkedin-blue.svg"}
                     alt={"linkedin"}/>
            </Link>}


            {!props.anonymous && getCvUrl(props?.candidate)!==""&& <a href={getCvUrl(props?.candidate)} target={"_blank"}>
                <img className={"cv__img-mobile"} src={"https://static.myshortlist.co/cv-blue-v2.svg"}
                     alt={"resume"}/>
            </a>}

            {!props.anonymous && getCvUrl(props.candidate)=== "" && <Popup trigger={<button className={"button-transparent"}  >
                <img className={"information__img-mobile-popup"} src={"https://static.myshortlist.co/phone-blue.svg"} alt={"phone"}/>
            </button>} position="left center">
                <div className={"placeholder"}><button className={"button-tel-mobile"} onClick={()=>{
                    navigator.clipboard.writeText(`${props?.candidate?.phone}`);
                }}> Copier le numéro </button></div>
            </Popup>}

            {!props.anonymous && <a href={`mailto:${props?.candidate?.email}`} target="_blank" rel="noopener noreferrer">

                <img className={"information__img-mobile"} src={"https://static.myshortlist.co/mail-blue.svg"}
                     alt={"phone"}/>
            </a> }

            {!props.anonymous && <a href={props?.candidate?.linkedin}
                                    target={"_blank"}>
                <img className={"information__img-mobile"} src={"https://static.myshortlist.co/linkedin-blue.svg"}
                     alt={"linkedin"}/>
            </a>}

            {!props.anonymous &&<div className={"share-circle"}>
                <button className={"button-transparent"} onClick={async() => {
                    try {

                        appContext.dispatchPanels({
                            type: "OPEN_WINDOW",
                            value: {
                                type: "shareCandidate",
                                data: {
                                    token: props.token
                                }
                            }
                        });
                    } catch(error) {
                        devLog(error);
                    }
                }}> <img className={"share__img-mobile"} src={"https://static.myshortlist.co/share-blue-v3.svg"}
                 alt={"partage"}/></button>
            </div>}

        </div>
    </>);
}


