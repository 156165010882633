import React, {useContext, useState} from "react";
import {isEmailValid} from "../../../utils/typeGuards";
import NavbarNewAccountMobile from "./NavbarNewAccountMobile";
import {Link} from "react-router-dom";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {AppContext, IAppContext} from "../../componentsApp/context/AppContext";

export default (props: any) => {

    const appContext: IAppContext = useContext(AppContext) as IAppContext;

    const[isSubmit, setIsSubmit]=useState(false);
    const isButtonStatusGood= isEmailValid(props.profile.mail) && props.profile.firstName && props.profile.lastName && props.profile.phone;
    const createNewAccount = async () => {
        try {

            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v3/accounts/new/client/account`, {
                mail:props.profile.mail,
                firstName:props.profile.firstName,
                lastName:props.profile.lastName,
                phone:props.profile.phone,
                businessName:props.profile.businessName,
                function:props.profile.function,
                nRecruitments:props.profile.nRecruitments,
                token:props.profile.token,
            }, {
                withCredentials: true
            })).data;

            devLog(status, data);
            props.dispatchProfile({
                type: "UPDATE_VALUE",
                value: {key: "clientId", value: data.client._id}
            });
            props.setStep(3);
            return notification(appContext.dispatchPanels, "success", "success");


        } catch (error) {
            devLog(error);
            notification(appContext.dispatchPanels, "Une erreur est survenue", "error");
        }
    }


    return (<>
        <div className={""}>

            <NavbarNewAccountMobile step={1} setStep={props.setStep}/>

            <Link
                to={`/card/new/${props.token}${props.search}`}>
                <button className={"button-back-mobile"}>
                    <img src={"https://static.myshortlist.co/Arrow-Back-Black.svg"}
                         alt={"backward"}/></button>
            </Link>

            <div className={"new-account-container-mobile"}>
            <div className={"new-account-information"}>
                <img className={"margin3"} src={"https://static.myshortlist.co/gabrielle.svg"} alt={"gabrielle"}/>
                <div>
                    <div className={"new-account-information-title"}> Gabrielle</div>
                    <div className={"new-account-information-description"}> Bienvenue ! Je suis Gabrielle et je vais
                        vous accompagner dans l'univers Black Belt !
                        Vous souhaitez accéder au profil de Clémentine et voir d'autres profils ultra-qualifiés ?
                        La création de votre compte ne prendra que 2 minutes.
                    </div>
                </div>
            </div>


            <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>E-mail*</div>
                <input className={"new-account-information-item-input"}
                       value={props.profile.mail} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "mail", value: e.target.value}
                    })
                }}/>
            </div>
                { isSubmit && !isEmailValid(props.profile.mail) && props.profile.mail &&<div className={"new-account-information-item-input_warning"}> Cet email n'est pas valide </div>}
                { isSubmit && !isEmailValid(props.profile.mail) && !props.profile.mail &&<div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre email </div>}


                <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Prénom*</div>
                <input className={"new-account-information-item-input"}
                       value={props.profile.firstName} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "firstName", value: e.target.value}
                    })
                }}/>

            </div>
                { isSubmit && !props.profile.firstName && <div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre prénom </div>}


                <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Nom*</div>
                <input className={"new-account-information-item-input"}
                       value={props.profile.lastName} onChange={(e) => {
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "lastName", value: e.target.value}
                    })
                }}/>
            </div>
                { isSubmit && !props.profile.lastName && <div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre nom </div>}


                <div className={"new-account-information-item"}>
                <div className={"new-account-information-item-title"}>Numéro de téléphone*</div>
                <input className={"new-account-information-item-input"} type={"number"}
                       value={props.profile.phone} onChange={(e) => {
                    if(e.target.value.length <11){
                    props.dispatchProfile({
                        type: "UPDATE_VALUE",
                        value: {key: "phone", value: e.target.value}
                    })}
                }}/>
            </div>
                { isSubmit && !props.profile.phone && <div className={"new-account-information-item-input_warning"}> Merci d'indiquer votre téléphone </div>}

                <br/>
            <button className={"new-account-start__button-blue"}
                onClick={() => {
                    if(!isButtonStatusGood){
                        setIsSubmit(true);
                    }
                    else{
                        createNewAccount()
                    }

                }}>
                <div className={"new-account-start__button-blue-content"}>Suivant</div>
                <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                     alt={"arrow"}/>
            </button>

            </div>

        </div>
    </>);
}