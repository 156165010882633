import {TNotifications} from "../components/componentsApp/state/IAppPanel";

export {notification};

const notification = async(dispatchPanels: Function, notificationText: string, notificationType: TNotifications) => {

    await dispatchPanels({
        type: "CLOSE_NOTIFICATIONS", value: null
    });

    await dispatchPanels({
        type: "OPEN_NOTIFICATIONS", value: {
            text: notificationText,
            type: notificationType,
        }
    });


    if(notificationType === "success") {
        const timer = setTimeout(() => {
            dispatchPanels({
                type: "CLOSE_NOTIFICATIONS", value: null
            });
            clearTimeout(timer);
        }, 500);
        return;
    }

    const timer = setTimeout(() => {
        dispatchPanels({
            type: "CLOSE_NOTIFICATIONS", value: null
        });
        clearTimeout(timer);
    }, 3500);


}