import React from "react";

export default (props: any) => {
    return (<>

            <div className={"ellipse-mobile"}>


                <div className={"page-apply-description-mobile"}>
                    <img className={""} src={"https://static.myshortlist.co/logo.png"} alt={"logo"}/>

                    <div className={"page-apply-position-wanted-mobile"}> Vous postulez pour le poste
                        de {props.mission?.positionWanted}</div>


                </div>

            </div>


            <div className={"page-to-apply-container-mobile"}>




                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Prénom*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.firstName} onChange={(e) => props.setFirstName(e.target.value)}/>
                </div>

                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Nom*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.name} onChange={(e) => props.setName(e.target.value)}/>

                </div>

                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Email*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.mail} onChange={(e) => props.setMail(e.target.value)}/>
                </div>

                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Téléphone*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.phone} onChange={(e) => props.setPhone(e.target.value)}/>
                </div>

                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Lien linkedin*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.linkedin} onChange={(e) => props.setLinkedin(e.target.value)}/>
                </div>

                <div className={"new-account-information-item-mobile"}>
                    <div className={"new-account-information-item-title-mobile"}>Salaire annuel brut souhaité*</div>
                    <input type={"text"} className={"new-account-information-item-input-mobile"}
                           value={props.salaryWanted} onChange={(e) => props.setSalaryWanted(e.target.value)}/>
                </div>

                <br/>
                <br/>

                {(!props.isFirstNameOk || !props.isLastNameOk || !props.isEmailOk || !props.isPhoneOk || !props.isLinkedinOk || !props.isSalaryOk) &&
                <div className={"page-apply-warning"}> {!props.isFirstNameOk  && <>prénom,</>} {!props.isLastNameOk   && <>nom,</>} {!props.isEmailOk  && <>email,</>} {!props.isPhoneOk  && <>téléphone,</>} {!props.isLinkedinOk  && <>lien linkedin,</>} {!props.isSalaryOk  && <>salaire</>} non valide(s) </div>}

                <button className={"new-account-start__button-blue"} disabled={!props.isButtonStatusGood} onClick={() => {
                    props.sendCandidacy()
                }}>
                    <div className={"new-account-start__button-blue-content"}>Postuler</div>
                    <img className={""} src={"https://static.myshortlist.co/arrow-going-up.svg"}
                         alt={"arrow"}/>
                </button>

                <br/>

            </div>
        </>
    )
}