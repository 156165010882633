import React, {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import NavbarPc from "../componentsFlashcardV2Page/NavbarPc";
import Cropper from "react-easy-crop";
import {AppLevelLoading} from "../AppLevel/AppLevelLoading";
export default (props: any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
    }, [])



    return(<>

        <NavbarPc anonymous={false}
                  token={props.token}
                  firstName={props?.candidate?.firstName}
                  setIndex={props?.setIndex}
                  proposition={props?.proposition}
                  index={props?.index}
                  displayVideo={true}
                  selected={props?.selected}
                  setSelected={props?.setSelected}
                  candidate={props?.candidate}
                  candidacyId={props.candidacyId}
                  blackBeltBusinessClientId={props?.blackBeltBusinessClientId}
        />

       {/*<div className={"display-video-pc-container"}>*/}

       {/*     <div className={"video-container"}>*/}
       {/*         <video  className={"video-content"} playsInline={true} controls={true} autoPlay={false}*/}
       {/*                 loop={false}*/}
       {/*                 muted={false}>*/}
       {/*             <source src={props?.candidate?.extendedProfile?.videoUrl} type="video/mp4"/>*/}
       {/*         </video>*/}
       {/*     </div>*/}
       {/*</div>*/}


        <video  className={"video-whithout-container"} playsInline={true} controls={true} autoPlay={false}
                loop={false}
                muted={false}>
            <source src={props?.candidate?.extendedProfile?.videoUrl} type="video/mp4"/>
        </video>




            {/*<Cropper*/}
            {/*    video={props?.candidate?.extendedProfile?.videoUrl}*/}
            {/*    crop={crop}*/}
            {/*    zoom={zoom}*/}
            {/*    aspect={5/3}*/}
            {/*    onCropChange={setCrop}*/}
            {/*    onCropComplete={onCropComplete}*/}
            {/*    onZoomChange={setZoom}*/}
            {/*/>*/}

        {/*<Link to={`/card/${props?.token}`}>*/}
        {/*    <button className={"video-close"}>*/}
        {/*        <img className={"margin3"} src={"https://static.myshortlist.co/zoom-out.svg"}*/}
        {/*             alt={"close"}/>*/}
        {/*    </button>*/}
        {/*</Link>*/}





    </>);
}