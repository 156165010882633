import React, {useState} from "react";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";

export default (props: any) => {

    const[displayPhone,setDisplayPhone]=useState(false);
    function getCvUrl(candidate: any) {
        if (candidate?.cvUrls?.length !== 0) {
            return (candidate?.cvUrls ?? [])[(candidate?.cvUrls?.length ?? 1) - 1] ?? "";
        }
        if (candidate.cvUrl) {
            return candidate?.cvUrl ?? "";
        }
        return "";
    }


    return (<>
        <div className={"contact"}>

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img"} src={"https://static.myshortlist.co/mail.svg"}
                     alt={"resume"} placeholder={"mail"}/>
            </Link>}

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img"} src={"https://static.myshortlist.co/phone.svg"}
                     alt={"phone"}/>
            </Link>}

            {props.anonymous && <Link to={`/new/account/${props.token}${props.search}`}>
                <img className={"information__img"} src={"https://static.myshortlist.co/linkedin-good.svg"}
                     alt={"linkedin"}/>
            </Link>}

            {!props.anonymous && getCvUrl(props.candidate)!== "" && <a href={getCvUrl(props.candidate)} target={"_blank"}>
                <img className={"cv__img"} src={"https://static.myshortlist.co/cv.png" +
                    ""}
                     alt={"resume"}/>
            </a>}

            {/*src={"https://static.myshortlist.co/resume.svg"}  src={"https://static.myshortlist.co/cv.svg"}*/}


            {!props.anonymous && getCvUrl(props.candidate)=== "" &&  <Popup trigger={<button
                className={"button-transparent"}>
                <img className={"information__img"} src={"https://static.myshortlist.co/phone.svg"} alt={"phone"}/>
            </button>} position="left center">
                <div className={"placeholder"}> <button className={"button-tel"} onClick={()=>{
                    navigator.clipboard.writeText(`${props?.candidate?.phone}`);
                }}> Copier le numéro </button> </div>
            </Popup>  }

            {!props.anonymous && <a href={`mailto:${props?.candidate?.email}?cc=jessica@black-belt.io`} target="_blank" rel="noopener noreferrer">

                <img className={"information__img"} src={"https://static.myshortlist.co/mail.svg"}
                     alt={"phone"} />
            </a> }

            {!props.anonymous && <a href={props.candidate?.linkedin}
                                    target={"_blank"}>
                <img className={"information__img"} src={"https://static.myshortlist.co/linkedin-good.svg"}
                     alt={"linkedin"}/>
            </a>}


        </div>
    </>);
}