import React from "react";


export default (props: any) => {

    return (<>
        <div className={"ellipse"}>

            <div className={"ellipse-content"}>

                <img className={"logo-white-and-black"} src={"https://static.myshortlist.co/logoWhiteAndBlack.svg"} alt={"logo"}/>
                <div className={"ellipse-img"}>

                    <div className={"img-gradient-new-account"}>
                    <img src={props?.candidate?.avatarUrl} alt={"clementine"}/>
                    </div>
                    <div className={"ellipse-img-jeanne"} >
                        <img className={"ellipse-little-img"} src={"https://static.myshortlist.co/jeanne2.jpg"} alt={"jeanne"}/>
                        <div className={"ellipse-img-name"}> Jeanne</div>
                    </div>
                    <div className={"ellipse-img-armand"}>
                        <img  className={"ellipse-little-img"} src={"https://static.myshortlist.co/armand2.jpg"} alt={"armand"}/>
                        <div className={"ellipse-img-name"}>Armand</div>
                    </div>
                    <div  className={"ellipse-img-coline"}>
                        <img className={"ellipse-little-img"} src={"https://static.myshortlist.co/coline2.jpg"} alt={"coline"}/>
                        <div className={"ellipse-img-name"}>Coline</div>
                    </div>



                </div>
                <div className={"ellipse-description"}>
                    <div className={"ellipse-description-title"}> Accéder à plein d'autres candidats</div>
                    <div className={"ellipse-description-content"}> Recruter n'est pas chose aisée mais avec Black Belt
                        cela devient un jeu d’enfant.
                    </div>
                </div>

            </div>

        </div>

    </>)
}