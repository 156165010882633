import React, {useContext, useEffect, useState} from "react";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import axios from "axios";
import {notification} from "../../utils/notification";
import {devLog} from "../../utils/devLog/devLog";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import DisplayVideoPC from "./componentsdisplayVideo/DisplayVideoPC";
import DisplayVideoMobile from "./componentsdisplayVideo/DisplayVideoMobile";




export default (props: any) => {

    const appContextV2: IAppContext = useContext(AppContext) as IAppContext;
    const token = props.match.params.token;
    const initialIndex=props.match.params.index;
    const [index,setIndex]:any = useState(initialIndex);
    const [mission, setMission] = useState();
    const [proposition, setProposition] :any = useState([]);
    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");
    const [selected,setSelected]:any=useState([]);
    const [blackBeltBusinessClientId,setBlackBeltBusinessClientId]=useState('');


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");
                const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/get/flashcard`, {
                    headers: {
                        token: token
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    setFetchStatus("ok");
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setFetchStatus("ok");
                devLog(data);
                setMission(data.mission);
                setProposition(data.proposition);
                setBlackBeltBusinessClientId(data.blackBeltBusinessClientId);

                return notification(appContextV2.dispatchPanels, "success", "success");


            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            let i=0;
            for(let item of proposition){
                if (item.selected===true){
                    setSelected((ps: any) => ([...ps, item.candidacy._id]));
                }
            }
        })();
    }, [proposition, setProposition]);

    useEffect(() => {
        (async () => {
            let i=0;
            for(let item of proposition){
                if (item._id===token){
                    setIndex(i);
                }
                i++;
            }
        })();
    }, [proposition, setProposition]);


    return(
        <PageLayout pageStatus={fetchStatus}>

            <div className={"hide-on-computers"}>
             <DisplayVideoMobile candidate={(proposition ?? [])[index]?.candidate}
                                 token={token}
                                 proposition={proposition}
                                 index={index}
                                 setIndex={setIndex}
                                 selected={selected}
                                 setSelected={setSelected}
                                 candidacyId={(proposition?? [])[index]?.candidacy._id}
                                 blackBeltBusinessClientId={blackBeltBusinessClientId}
             />
            </div>

            <div className={"hide-on-tablets-and-smartphones"}>
            <DisplayVideoPC candidate={(proposition ?? [])[index]?.candidate}
                            token={token}
                            proposition={proposition}
                            index={index}
                            setIndex={setIndex}
                            selected={selected}
                            setSelected={setSelected}
                            candidacyId={(proposition?? [])[index]?.candidacy._id}
                            blackBeltBusinessClientId={blackBeltBusinessClientId}
            />
            </div>
        </PageLayout>
       );
}