import React from "react";

export default (props: any) => {
    return (<>


            <div className={"ellipse-mobile"}>


                <div className={"page-apply-description-mobile"}>
                    <img className={""} src={"https://static.myshortlist.co/logo.png"} alt={"logo"}/>

                    <div className={"page-apply-position-wanted-mobile"}> Vous postulez pour le poste
                        de {props.mission?.positionWanted}</div>


                </div>

            </div>



            <div className={"page-to-apply-success-container-mobile"}>

                <div className={"new-account-information"}>
                    <div>

                        { props?.candidacyStatus==="tooManyApplies" &&
                        <div className={"page-apply-success-description-mobile"}>
                            vous avez déjà postulé pour ce poste !
                            L'équipe black-belt revient vers vous au plus vite...
                        </div>}

                        { props?.candidacyStatus==="noData" &&
                        <div className={"page-apply-success-description-mobile"}>
                            Certaines données obligatoires n'ont pas été fournies!
                            Votre candidature n'a malheureusement pas pu etre enregistrée ...
                        </div>}

                        { props?.candidacyStatus!=="tooManyApplies" &&  props?.candidacyStatus!=="noData"&&
                        <div className={"page-apply-success-description-mobile"}>
                            Votre candidature a bien été enregistrée !
                            L'équipe black-belt revient vers vous au plus vite...
                        </div>}

                    </div>
                </div>

            </div>
        </>
    )
}