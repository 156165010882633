import React from "react";

export default (props:any) => {

    return (<div className={"wants--container"}>
        <div className={`${props.pc ? "wants__title":"wants__title-on-mobile"}`}> Ses Envies </div>
        <div className={`${props.pc ? "section__content":"section__content-on-mobile"}`} style={{whiteSpace: "pre-wrap"}}>
            {props?.wants}
        </div>
    </div>);
}