import React, {useContext, useEffect, useState} from "react";
import FlashcardV2MobileAndTablets from "./componentsFlashcardV2Page/FlashcardV2MobileAndTablets";
import FlashcardV2PC from "./componentsFlashcardV2Page/FlashcardV2PC";
import {AppContext, IAppContext} from "../componentsApp/context/AppContext";
import {notification} from "../../utils/notification";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";

export default (props: any) => {

    const appContextV2: IAppContext = useContext(AppContext) as IAppContext;
    const token = props.match.params.token;
    const [mission, setMission] = useState();
    const [proposition, setProposition]:any = useState([]);
    const [tokenFlashcard, setTokenFlashcard]:any = useState(token);
    const [index, setIndex]:any= useState();
    const [selected,setSelected]:any=useState([]);
    const [blackBeltBusinessClientId,setBlackBeltBusinessClientId]=useState('');
    const [blackBeltBusiness, setBlackBeltBusiness]=useState();



    const [fetchStatus, setFetchStatus]: ["error" | "loading" | "forbidden" | "loaded" | "ok", Function] = useState("loading");


    useEffect(() => {
        (async () => {
            try {
                setFetchStatus("loading");
                const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/get/flashcard`, {
                    headers: {
                        token: token,
                    },
                    withCredentials: true
                })).data;


                if (status !== "ok") {
                    setFetchStatus("ok");
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setFetchStatus("ok");
                devLog(data);
                setMission(data.mission);
                setProposition(data.proposition);
                setBlackBeltBusinessClientId(data.blackBeltBusinessClientId);
                setBlackBeltBusiness(data.blackBeltBusiness);
                return notification(appContextV2.dispatchPanels, "success", "success");


            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, [tokenFlashcard, token, setTokenFlashcard]);


    useEffect(() => {
        (async () => {
            let i=0;
           for(let item of proposition){
               if (item.selected===true){
                   setSelected((ps: any) => ([...ps, item.candidacy._id]));
               }
           }
        })();
    }, [proposition, setProposition]);

    useEffect(() => {
        (async () => {
            let i=0;
            for(let item of proposition){
                if (item._id===tokenFlashcard){
                    setIndex(i);
                }
                i++;
            }
        })();
    }, [proposition, setProposition, token, tokenFlashcard, setTokenFlashcard]);


    return (<PageLayout pageStatus={fetchStatus}>

        <div className={"hide-on-tablets-and-computers"}>
            <FlashcardV2MobileAndTablets anonymous={false}
                                         token={token}
                                         setIndex={setIndex}
                                         proposition={proposition}
                                         index={index}
                                         selected={selected}
                                         setSelected={setSelected}
                                         blackBeltBusinessClientId={blackBeltBusinessClientId}
                                         blackBeltBusiness={blackBeltBusiness}
                                         data={{
                                             mission: mission,
                                             candidate: (proposition?? [])[index]?.candidate,
                                             candidacy: (proposition?? [])[index]?.candidacy,
                                         }}/>
        </div>

        <div className={"hide-on-smartphones"}>
            <FlashcardV2PC anonymous={false}
                           token={token}
                           setIndex={setIndex}
                           proposition={proposition}
                           index={index}
                           selected={selected}
                           setSelected={setSelected}
                           blackBeltBusinessClientId={blackBeltBusinessClientId}
                           blackBeltBusiness={blackBeltBusiness}
                           data={{
                               mission: mission,
                               candidate: (proposition?? [])[index]?.candidate,
                               candidacy: (proposition?? [])[index]?.candidacy,
                           }}
                           />
        </div>

    </PageLayout>)
}