import React from "react";
import MissionMobile from "./MissionMobile";

export default (props: any) => {
    return( <body className={"client-page__container"}>

        <br/><br/><br/>
        <div className={"client-page__navbar-business-page-mobile"}>
            { props?.categoryToDisplay==="inProgress"&& <h1 className={"client-page__title-mobile"}> Postes en cours</h1>}
            { props?.categoryToDisplay==="recruited"&& <h1 className={"client-page__title-mobile"}> Postes recruté</h1>}
            { props?.categoryToDisplay==="canceled"&& <h1 className={"client-page__title-mobile"}> Postes annulé</h1>}
        </div>

        <div className={"subnav-mobile"}>
            <button className={`filter-business-page-mobile`} onClick={() => {props?.setCategoryToDisplay("inProgress")}}>
                <img className={"filter-business-page_img"} src={"https://static.myshortlist.co/in-progress.svg"}/>
                En cours
            </button>
            <button className={`filter-business-page-mobile`} onClick={() => {props?.setCategoryToDisplay("recruited")}}>
                <img className={"filter-business-page_img"} src={"https://static.myshortlist.co/recruited.svg"}/>
                Recruté
            </button>
            <button className={`filter-business-page-mobile`} onClick={() => {props?.setCategoryToDisplay("canceled")}}>
                <img className={"filter-business-page_img-canceled"} src={"https://static.myshortlist.co/canceled-v2.svg"}/>
                Annulé
            </button>
        </div>

        <br/> <br/>

        <div className={"client-page__missions"}>
            {props?.missions?.filter((item: any) => (item.blackBeltStatus === props?.categoryToDisplay)).map((item: any) => (
                <MissionMobile item={item}/>))}

            {props?.missions?.filter((item: any) => (item.blackBeltStatus === props?.categoryToDisplay)).length===0 && <><br/><br/><br/><br/><br/></>}
        </div>

        <br/>
        <div className={"text--center margin-left-right-5"}>
            Pour nous confier un nouveau recrutement, contacter Dan via le mail dan@black-belt.io ou par téléphone au 01 84 80
            60 62.
        </div>
        <br/>
        <br/><br/>
        </body>
    )
};