/**
 * All the routes here are generated in the Routing component
 * public are the routes that don't need authentication to access
 * private are routes that needs authentication to access
 * shared are routes that should always be available to anyone (example: a callback sent by email should point here)
 * Public and private routes MUST have a route pointing to the path "/", but right now the landing pages are not taken from here, should be refactored
 */

import {PrivateSpaceLanding} from "../components/privateSpacePages/PrivateSpaceLanding";
import {FunctionComponent} from "react";
import {PrivatePage} from "../components/privateSpacePages/PrivatePage";
import {ActionPage} from "../components/publicSpacePages/ActionPage";
import {LandingPage} from "../components/publicSpacePages/LandingPage";
import FlashcardV2Page from "../components/publicSpacePages/FlashcardV2Page";
import FlashcardV2AnonymousPage from "../components/publicSpacePages/FlashcardV2AnonymousPage";
import {NewAccount} from "../components/publicSpacePages/NewAccount";
import PageToApply from "../components/publicSpacePages/PageToApply";
import PageCareer from "../components/publicSpacePages/PageCareer";
import DisplayVideo from "../components/publicSpacePages/DisplayVideo";
import BusinessPage from "../components/publicSpacePages/BusinessPage";
import Cooptation from "../components/publicSpacePages/Cooptation";
import {CandidateInterestedUpdate} from "../components/publicSpacePages/CandidateInterestedUpdate";

export {routes};

export interface IRoute {
  path: string;
  page: string;
  component: FunctionComponent<any>;
  exact: boolean;
}

interface IRoutes {
  public: IRoute[];
  private: IRoute[];
}

const routes: IRoutes = {
  public: [],
  private: [],
};

// --------------------------- Public routes
routes.public.push({exact: true, path: '/card/:token', page: 'FlashcardV2Page', component: FlashcardV2Page});
routes.public.push({exact: true, path: '/proposition/:token', page: 'PropositionPage', component: LandingPage});
routes.public.push({exact: true, path: '/card/new/:token', page: 'FlashcardV2AnonymousPage', component: FlashcardV2AnonymousPage});
routes.public.push({exact: true, path: '/business/:token', page: 'BusinessPage', component: BusinessPage});
routes.public.push({exact: true, path: '/action/:actionName/:actionToken', page: 'ActionPage', component: ActionPage});
routes.public.push({exact: true, path: '/new/account/:token', page: 'NewAccount', component: NewAccount});
routes.public.push({exact: true, path: '/video/:token', page: 'DisplayVideo', component: DisplayVideo});
routes.public.push({exact: true, path: '/apply/:slugname/:slugnumber/:rank/apply', page: 'PageToApply', component: PageToApply});
routes.public.push({exact: true, path: '/career', page: 'PageCareer', component: PageCareer});
routes.public.push({exact: true, path: '/coopt/:missionRef', page: 'cooptation', component: Cooptation});
routes.public.push({exact: true, path: '/interested/:cid/:status', page: 'CandidateInterestedUpdate', component: CandidateInterestedUpdate});




// --------------------------- Private routes
routes.private.push({exact: true, path: '/private', page: 'PrivateSpaceLanding', component: PrivatePage}); // Route exemple