import React, {useState} from "react";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import ButtonForward from "./ButtonForward";

export default (props: any) => {

    const[displayPhone,setDisplayPhone]=useState(false);
    function getCvUrl(candidate: any) {
        if (candidate?.cvUrls?.length !== 0) {
            return (candidate?.cvUrls ?? [])[(candidate?.cvUrls?.length ?? 1) - 1] ?? "";
        }
        if (candidate.cvUrl) {
            return candidate?.cvUrl ?? "";
        }
        return "";
    }

    return (<>

        <div className={`${props.pc ? "section__content_social-network" : "section__content-on-mobile"}`}>

            <div className={"social-network-mobile"}>

                <a href={`mailto:${props?.candidate?.email}?cc=jessica@black-belt.io`} target="_blank" rel="noopener noreferrer">

                    <img className={"social-network-item"} src={"https://static.myshortlist.co/mail-v7.svg"}
                         alt={"mail"} />
                </a>

                {getCvUrl(props.candidate)!== "" && <a href={getCvUrl(props.candidate)} target={"_blank"}>
                    <img className={"social-network-item"} src={"https://static.myshortlist.co/cv-v8.svg" +
                        ""}
                         alt={"resume"}/>
                </a>}

                { getCvUrl(props.candidate)=== "" &&  <Popup trigger={<button
                    className={"button-transparent"}>
                    <img className={"social-network-item"} src={"https://static.myshortlist.co/phone-v8.svg"} alt={"phone"}/>
                </button>} position="top center">
                    <div className={""}> <button className={"button-tel"} onClick={()=>{
                        navigator.clipboard.writeText(`${props?.candidate?.phone}`);
                    }}> Copier le numéro </button> </div>
                </Popup>  }



                <a href={props.candidate?.linkedin}
                   target={"_blank"}>
                    <img className={"social-network-item"} src={"https://static.myshortlist.co/linkedin-v7.svg"}
                         alt={"linkedin"}/>
                </a>


                <ButtonForward navbar={false}
                               blackBeltBusinessClientId={props?.blackBeltBusinessClientId}
                               token={props?.token}/>

            </div>
        </div>
    </>);
}