import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {PageLayout} from "../sharedComponents/layouts/PageLayout";
import Spaces from "../sharedComponents/components/Spaces";


export {CandidateInterestedUpdate};

const CandidateInterestedUpdate = (props: any) => {

    const cid: string = props.match.params.cid || "";
    const status: string = props.match.params.status || "";

    useEffect(() => {
        (async() => {
            try {
                const fetched = await axios.put(`${process.env.REACT_APP_GATEWAY_URL}/v3/jobs/interested/${cid}/${status}`, {
                } ,{
                    withCredentials: true
                });
            } catch(error) {
                devLog(error);
            }
        })();
    }, []);

    return (<PageLayout  pageStatus={"ok"}>


        <div className={"navbar"}>
            <div className={"navbar_left"}>
                <a href={"https://www.black-belt.io/"}>
                <img className={""} src={"https://static.myshortlist.co/logo-black.svg"} alt={"logo"}/>
                </a>
            </div>

            <div>
                <a href={"https://www.black-belt.io/offres-d-emploi"}>
                <button className={"button-select-candidate-navbar"}>
                    Les meilleurs jobs sont ici
                    <img className={"margin3"} src={"https://static.myshortlist.co/flash-white.svg"}
                         alt={"arrow"}/>
                </button>
                </a>
            </div>
        </div>


          <Spaces n={10}/>

        <div className={"text--center"}>

            Merci ! Votre choix a bien été enregistré 🙂
            <br/><br/>
            Vous avez précisé que {status !== "interested" && <span> vous n'étiez pas intéressé(e).</span>} {status === "interested" && <span>vous étiez intéressé(e).</span>}
            <br/><br/>
            Vous vous êtes trompé(e) et souhaitez modifier votre choix ?  Sélectionnez simplement l'autre option sur le même mail.
            <br/><br/>
            A très vite !
        </div>



    </PageLayout>);

}

